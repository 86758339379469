import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

import { DonationService } from "@gift/donation/donation.service";
import { Acknowledgee } from "@gift/donation/bb-acknowledgee";

import { LoggerService } from "@shared/logger.service";
import { ConstantsService } from "@shared/constants.service";
import { TextValidators } from '@app/shared/validators/text-validators';

@Component({
  selector: 'app-acknowledgee',
  templateUrl: './acknowledgee.component.html',
  styleUrls: ['./acknowledgee.component.css'],
  providers: [LoggerService]
})
export class AcknowledgeeComponent implements OnInit {

  // @Input() inHonorOrMemory: boolean;
  isAcknowledgee: boolean = false;

  acknowledgeeForm: UntypedFormGroup;

  showAddress: boolean = false;
  showPostalCode: boolean = true;
  showState: boolean = true;

  public constants = ConstantsService;

  // Form fields
  get firstName() { return this.acknowledgeeForm.get("firstName"); }
  get lastName() { return this.acknowledgeeForm.get("lastName"); }

  constructor(public donationService: DonationService,
    private formBuilder: UntypedFormBuilder,
    private logger: LoggerService) {

    this.logger.context.push("acknowledgee");
  }


  /**
   * Watch for updates to the acknowledgee checkbox
   * @param event checkbox event
   */
  onIsAcknowledgeeChanged(event) {
    this.logger.context.push("onIsAcknowledgeeChanged");
    if (event.checked) {
      this.logger.debug("Acknowledgee: ", this.donationService.acknowledgee);
      this.showAddress = true;
      // Enable validators
      this.firstName.setValidators([Validators.required, Validators.maxLength(ConstantsService.firstNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.lastName.setValidators([Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.acknowledgeeForm.get("country").setValidators([Validators.required]);
      this.acknowledgeeForm.get("addressLines").setValidators([Validators.required, Validators.maxLength(ConstantsService.addressLinesCharacterLimit)]);
      this.acknowledgeeForm.get("city").setValidators([Validators.required, Validators.maxLength(ConstantsService.cityCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.acknowledgeeForm.get("state").setValidators([Validators.required]);
      this.acknowledgeeForm.get("postalCode").setValidators([Validators.required]);

    } else {
      // Disable validators
      this.logger.debug("Disabling validators");
      this.acknowledgeeForm.get("firstName").setValidators(null);
      this.acknowledgeeForm.get("lastName").setValidators(null);
      this.acknowledgeeForm.get("country").setValidators(null);
      this.acknowledgeeForm.get("addressLines").setValidators(null);
      this.acknowledgeeForm.get("city").setValidators(null);
      this.acknowledgeeForm.get("state").setValidators(null);
      this.acknowledgeeForm.get("postalCode").setValidators(null);
      // Clear object
      this.donationService.acknowledgee = null;
      // Clear form
      this.acknowledgeeForm.reset();
      this.logger.context.pop();
    }
  }

  /**
   * Save values from form into donation service acknowledgee
   * @param acknowledgee Data that is going to be used to persist
   * to the donation service
   */
  private saveAcknowledgee(acknowledgee: Acknowledgee): void {
    this.logger.context.push("saveAcknowledgee");

    this.donationService.acknowledgee = acknowledgee;
    this.logger.debug("Acknowledgee: ", acknowledgee);

    this.logger.context.pop();
  }

  /** Retrieve acknowledgee information from donation service */
  private getAcknowledgee(): void {
    this.acknowledgeeForm.setValue(this.donationService.acknowledgee);

    this.showAddress = true;
  }

  /** Watch for form changes and update the donation acknowlegee object */
  private onFormChanges(): void {
    this.logger.context.push("onFormchanges");
    this.acknowledgeeForm.valueChanges.subscribe(val => {
      this.logger.debug("Form values changed: ", val);
      // Call constructor to create an object of type Acknowledgee
      var acknowledgee = new Acknowledgee(<Acknowledgee>val);
      // Save
      this.saveAcknowledgee(acknowledgee);
    });
    this.logger.context.pop();
  }


  ngOnInit() {
    this.logger.context.push("ngOnInit");
    this.acknowledgeeForm = this.formBuilder.group({
      firstName: [],
      lastName: [],
      country: [],
      addressLines: [],
      apt: [],
      city: [],
      state: [],
      postalCode: []
    });

    this.logger.debug("Acknowledgee: ", this.donationService.acknowledgee);
    if (this.donationService.acknowledgee && this.donationService.acknowledgee.firstName) {
      // TODO: Setting acknowledgee to null is not causing this check to be false now. Checking first name is a bit hacky. Find a better way
      this.isAcknowledgee = true;

      // Load acknowledgee info from donation service
      this.getAcknowledgee();
    }

    this.onFormChanges();
    this.logger.context.pop();
  }

}
