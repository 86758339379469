<div class="pt-3 less-emphasized-text">
  Payment Type
</div>

<div *ngIf="form">
  <form [formGroup]="form">
    <div class="btn-group d-flex">
      <label *ngIf="donationService.giftType != GiftType.Recurring"
             id="paymentTypeCreditCard"
             [ngClass]="{
             'btn btn-selection w-100' : true,
             'active' : paymentType.value === PaymentType.CreditCard}">
        <input type="radio" formControlName="paymentType" [value]="PaymentType.CreditCard" /> Credit Card
      </label>
      <label *ngIf="donationService.giftType == GiftType.Recurring"
             id="paymentTypePayrollDeduction"
             [ngClass]="{
             'btn btn-selection w-100' : true,
             'active' : paymentType.value == PaymentType.PayrollDeduction}">
        <input type="radio" formControlName="paymentType" [value]="PaymentType.PayrollDeduction" /> Payroll Deduction
      </label>
    </div>
  </form>
  <div class="alert alert-danger" *ngIf="form.invalid && submitting">
    Please select a Payment Type
  </div>

</div>

