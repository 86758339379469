

<div class="container">
  <!--<div class="d-flex"><div class="pt-3 pb-3 search-separator">Progress Bar Goes Here!</div></div>-->
  <ngx-spinner bdColor="rgba(51,51,51,0.8)"
               size="medium"
               color="#fff"
               type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading</p>
  </ngx-spinner>
  <router-outlet></router-outlet>
</div>
<app-footer></app-footer>

<!-- TODO: Add enough margin above header to fit the To Top button -->
<button id="toTopButton" *ngIf="showToTop" (click)="goToTop()"
        [ngClass]="{'btn btn-navigation' : true}"
        [ngStyle]="{'bottom.px': bottom}">
  <i class="fa fa-arrow-up"></i>

</button>
