import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";

import { IGift } from "../../gift";
import { GiftService } from "../../gift.service";
import { IDesignation } from "../../designation";
import { DesignationService } from "../../designation.service"
import { HelperService } from "../../..//shared/helper.service";
import { NgxSpinnerService } from "ngx-spinner";
import swal from "sweetalert2";
import { LoggerService } from "../../../shared/logger.service";

@Component({
  selector: 'app-gift-add-referral',
  templateUrl: './gift-add-referral.component.html',
  styleUrls: ['./gift-add-referral.component.css'],
  providers: [LoggerService]
})
export class GiftAddReferralComponent implements OnInit {

  pageTitle: string = "You Have Selected The Following Fund:";

  addForm: UntypedFormGroup;

  fund: IDesignation;
  amount: number;
  appealId: string;

  submitting: boolean = false;
  attempts: number = 0;

  errorMessage: string;

  constructor(
    private spinnerService: NgxSpinnerService,
    private giftService: GiftService,
    private designationService: DesignationService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private logger: LoggerService) { }


  /**
   * Capture amount change in child component and update local value
   * @param amount Amount emitted by amount component
   */
  onAmountChanged(amount: number): void {
    this.amount = amount;
    this.amountControl.setValue(amount);
  }

  /**
   * Attempt to build a Gift object and save it
   */
  onSubmit(): void {
    this.logger.context.push("onSubmit");
    this.submitting = true;

    // Check if form is valid
    if (!this.addForm.valid) {
      // Stop submission if form is not valid and increment counter
      this.attempts++;
      this.logger.debug("Form invalid. Attempts: ", this.attempts);
      return;
    }

    // Build gift object
    let gift: IGift = { amount: this.amount, designation: this.fund, appealId: this.appealId };

    this.logger.debug("Gift: ", gift);
    this.giftService.addGift(gift).then(
      data => {
        this.logger.debug("Gift updated: ", data);
        swal.fire({
          title: "Added!",
          text: `Your gift for $${gift.amount} to ${this.fund.publicName} has been saved`,
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.router.navigateByUrl("/review");
        HelperService.goToTop();
      },
      error => {
        this.errorMessage = <any>error;
        this.logger.error("", error);
      });
    this.logger.context.pop();
  }

  get amountControl() { return this.addForm.get("amount"); }


  ngOnInit() {

    this.logger.loggerReady().then(
      data => {
        this.logger.context.push("ngOnInit");
        this.addForm = this.formBuilder.group({
          amount: ["", [Validators.required, Validators.min(1), Validators.max(99999)]]
        });


        // Get fund id from parameter
        const id = this.route.snapshot.paramMap.get("id");

        // Try to get an appeal id if one exists
        this.appealId = this.route.snapshot.paramMap.get("appealId");
        this.logger.debug("AppealId: ", this.appealId);
        
        if (id) {
          // Load fund information
          this.designationService.getDesignation(id).then(
            data => {
              this.logger.debug("Fund: ", data);
              this.fund = data;

              // Stop spinner
              this.spinnerService.hide();

              // TODO: If a fund could not be found send them to the add-gift page
            });
        } else {
          // If there isn't a fund id in the parameter send them to the normal add gift page
          this.router.navigateByUrl("/add-gift");
        }
        this.logger.context.pop();
      },
      err => {
        this.logger.error("", err);
      });


  }

}
