import { Injectable } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from "@angular/common/http";
import { observable, throwError } from "rxjs";
import { AppConfigService } from "../../app-config.service";
import { ISsoUser } from "./sso-user";

@Injectable({
  providedIn: 'root'
})
export class SsoUserService {
  private apiUrl: string;

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService) { }

  async checkConfigSettings() {
    // TODO: Factor this out into a shared class that can be called from other services
    // Check if settings has already been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
    }
    // Use data to update local variables
    this.apiUrl = AppConfigService.settings.webApi.url + "/ssouser";
  }

  /**
   * Retrieve SSO User from API and return
   */
  async getSsoUser(): Promise<ISsoUser> {
    await this.checkConfigSettings();

    return this.http.get<ISsoUser>(this.apiUrl).toPromise();
  }

}
