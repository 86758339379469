import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "../app-routing.module";
import { BrowserModule } from '@angular/platform-browser';
import { NgSelectModule } from "@ng-select/ng-select";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";

import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatFormFieldModule } from "@angular/material/form-field"
import { MatSelectModule } from "@angular/material/select";
import { MatInputModule } from "@angular/material/input";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule, MatRippleModule } from "@angular/material/core";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";


import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { AddressComponent } from './address/address.component';
import { ValidationFailureComponent } from './errors/validation-failure/validation-failure.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AppRoutingModule,
        BrowserModule,
        ReactiveFormsModule,
        NgSelectModule,
        SweetAlert2Module.forRoot(),
        HttpClientModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        NgxMatSelectSearchModule,
        MatRippleModule

    ],
    declarations: [
        ProgressBarComponent,
        AddressComponent,
        ValidationFailureComponent
    ],
    exports: [
        FormsModule,
        CommonModule,
        ProgressBarComponent,
        AddressComponent,
        BrowserModule,
        ReactiveFormsModule,
        NgSelectModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatExpansionModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatNativeDateModule,
        SweetAlert2Module,
        AppRoutingModule,
        NgxMatSelectSearchModule,
        MatRippleModule,
        ValidationFailureComponent


    ]
})
export class SharedModule {
}
