

export interface ICountry {
  Id: string;
  Description: string;
  Abbreviation: string;
}

export class Country implements ICountry {
  Id: string;
  Description: string;
  Abbreviation: string;

  /** Returns country description */
  toString() {
    return this.Description;
  }

  constructor(id: string, description: string, abbreviation: string) {
    this.Id = id;
    this.Abbreviation = abbreviation;
    this.Description = description;
    
  }

}
