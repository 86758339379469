<!--
<form action="https://javamatch.matchinggifts.com/search/companyprofile/ucsd_iframe">
  <div class="input-group">
    <input id="inputOrgId" name="inputOrgId" type="hidden" />
    <input class="form-control" type="text" placeholder="Company Name" id="org_search_value" name="org_search_value"/>
    <div class="input-group-append">
      <button class="btn btn-navigation btn-search" type="submit"><i class="fa fa-search"></i></button>
    </div>
  </div>
</form>
-->
<div class="iframe-container">
  <iframe src="https://javamatch.matchinggifts.com/ucsd_iframe"
          id="matchingGift"
          scrolling="yes"
          (load)="onLoad()"></iframe>
</div>
