import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from "../app-config.service";
import { LoggerService } from "./logger.service";
import { ICountry} from "./country";
import { ICountryViewModel } from "./country-view-model";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private countryApiUrl: string; //= AppConfigService.settings.webApi.url + "/country";


  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService,
    private logger: LoggerService) { }

  /**
   * Get a list of countries from API
   */
  async getCountries(): Promise<ICountry[]> {
    await this.checkConfigSettings();

    return this.http.get<ICountry[]>(`${this.countryApiUrl}/country`)
      .pipe(tap(data => {
          this.logger.debug("countries: ", data);
        }),
        catchError(err => this.handleError(err))
      ).toPromise();
  }

  /**
   * Retrieve a country view model containing a list of states, address
   * captions, and has state/zip information
   * @param id
   */
  async getCountry(id: string): Promise<ICountryViewModel> {
    await this.checkConfigSettings();

    return this.http.get<ICountryViewModel>(`${this.countryApiUrl}/country/${id}`)
      .pipe(tap(data => {
        this.logger.debug("country view model: ", data);
      }),
        catchError(err => this.handleError(err))
      ).toPromise();
  }

  async checkConfigSettings() {
    // Check if settings has already been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
    }
    // Use data to update local variables
    this.countryApiUrl = AppConfigService.settings.webApi.url;

  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error(`HttpError`, err);
    return throwError(errorMessage);
  }

}



