import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import {BehaviorSubject, throwError} from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { AppConfigService } from "../../app-config.service";
import { IDonor, Donor } from "./donor";
import { IState, State } from "../../shared/state";
import { ICountry, Country } from "../../shared/country";
import { ITribute } from "./tribute";
import { IDonation, IDonationReturn, IDonationId } from "./donation";
import { IGift } from "../gift";
import { GiftService } from "../gift.service";
import { GiftType } from "./gift-type.enum";
import { PaymentType } from "./payment-type.enum";
import { IBbRecurrence } from "./bb-recurrence";
import { RecurrenceType } from "./recurrence-type.enum"
import { Acknowledgee } from "./bb-acknowledgee";
import { ISsoUser } from "../../shared/sso-user/sso-user";
import { CountryService } from "../../shared/country.service";

import { ConstantsService } from "../../shared/constants.service";

import { LoggerService } from "../../shared/logger.service";

// TODO: Refactor this class!

@Injectable({
  providedIn: 'root'
})
export class DonationService {
  private bbApiUrl: string;
  private apiUrl: string;
  private fdnMerchantAccountId: string;
  private regMerchantAccountId: string;

  private bbspReturnUri: string; // = AppConfigService.settings.bbConfigs.bbspReturnUri;

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  private _donor: Donor;
  get donor(): Donor {
    return this._donor;
  }
  set donor(value: Donor) {
    this._donor = value;
  }
  private _state: IState;
  get state(): IState {
    return this._state;
  }
  set state(value: IState) {
    this._state = value;
  }
  private _country: ICountry;
  get country(): ICountry {
    return this._country;
  }
  set country(value: ICountry) {
    this._country = value;
  }
  private _ssoUser: ISsoUser;
  get ssoUser(): ISsoUser {
    return this._ssoUser;
  }
  set ssoUser(value: ISsoUser) {
    this._ssoUser = value;
  }

  private _tribute: ITribute;
  get tribute(): ITribute {
    return this._tribute;
  }
  set tribute(value: ITribute) {
    this._tribute = value;
  }

  private _giftType: GiftType;
  get giftType(): GiftType {
    return this._giftType;
  }
  set giftType(value: GiftType) {
    this._giftType = value;
    this.giftType$.next(value);
  }
  // add behavior subject to track changes to gift type
  giftType$ = new BehaviorSubject<GiftType>(null);



  private _paymentType: PaymentType;
  get paymentType(): PaymentType {
    return this._paymentType;
  }
  set paymentType(value: PaymentType) {
    this._paymentType = value;
  }

  private _recurrence: IBbRecurrence;
  get recurrence(): IBbRecurrence {
    return this._recurrence;
  }
  set recurrence(value: IBbRecurrence) {
    // TODO: Setting the day of month here does not appear to be working. Current workaround is to set it when passing the value to the donation object
    if (value && value.startDate) {
      var startDate = new Date(value.startDate);
      value.dayOfMonth = startDate.getDate();
    }
    this._recurrence = value;
  }

  private _comments: string;
  get comments(): string {
    return this._comments;
  }
  set comments(value: string) {
    this._comments = value;
  }

  private _isCorporate: boolean;
  get isCorporate(): boolean {
    return this._isCorporate;
  }
  set isCorporate(value: boolean) {
    this._isCorporate = value;
  }

  private _isAnonymous: boolean;
  get isAnonymous(): boolean {
    return this._isAnonymous;
  }
  set isAnonymous(value: boolean) {
    this._isAnonymous = value;
  }

  private _acknowledgee: Acknowledgee;
  get acknowledgee(): Acknowledgee {
    return this._acknowledgee;
  }
  set acknowledgee(value: Acknowledgee) {
    this._acknowledgee = value;
  }

  private _giftTotal: number;
  get giftTotal(): number {
    return this._giftTotal;
  }
  set giftTotal(value: number) {
    this._giftTotal = value;
  }

  private _donationId: string;
  get donationId(): string {
    return this._donationId;
  }
  set donationId(value: string) {
    this._donationId = value;
  }


  constructor(private httpClient: HttpClient,
    private readonly appConfigService: AppConfigService,
    private readonly giftService: GiftService,
    private readonly countryService: CountryService,
    private readonly logger: LoggerService) {

    this.logger.context.push("donation-service");


  }

  async checkConfigSettings() {
    this.logger.context.push("checkConfigSettings");
    // TODO: Factor this out into a shared class that can be called from other services
    // Check if settings has already been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
      this.logger.info("Config settings loaded: ", AppConfigService.settings);
    }
    // Use data to update local variables
    this.bbApiUrl = `${AppConfigService.settings.bbConfigs.apiUrl}/Donation`;
    this.apiUrl = `${AppConfigService.settings.webApi.url}`;

    this.fdnMerchantAccountId = AppConfigService.settings.bbConfigs.fdnMerchantAccountId;
    this.regMerchantAccountId = AppConfigService.settings.bbConfigs.regMerchantAccountId;

    this.bbspReturnUri = AppConfigService.settings.bbConfigs.bbspReturnUri;


    this.logger.context.pop();
  }

  /**
   * Create a donation and send it to Blackbaud
   */
  async createDonation(): Promise<IDonationReturn> {
    this.logger.context.push("createDonation");
    await this.checkConfigSettings();

    let donation = await this.generateBaseDonationObject();

    // TODO: Should probably create a DTO donor object rather than hackily casting it as an interface that it doesn't really match
    donation.Donor = <IDonor>this.donor.getBbDonor();

    // If there are no gifts in the cart throw an error
    // Specific error text is read by the calling method to re-route the user
    if (donation.gift.designations.length === 0) {
      throw new Error(ConstantsService.noGiftsInCart);
    }

    donation.bbspTemplateSitePageId = 624;
    donation.bbspReturnUri = this.bbspReturnUri;

    this.logger.debug("Donation: ", donation);
    this.logger.debug("BBSPReturnURI: ", this.bbspReturnUri);

    var result = this.httpClient.post<IDonationReturn>(this.bbApiUrl + "/Create", donation).toPromise();

    this.logger.context.pop();
    return result;
  }

  /**
   * Create a non BBPS donation and sent to web api to commit to CRM
   */
  async createOnlineGivingDonation(): Promise<string> {
    this.logger.context.push("createOnlineGivingDonation");

    let donation = await this.generateBaseDonationObject();

    // Add existing donation id
    donation.Id = this.donationId;

    donation.Donor = this.donor;

    // Get payment method
    donation.gift.paymentMethod = this.paymentType;

    if (donation.gift.designations.length === 0) {
      throw new Error(ConstantsService.noGiftsInCart);
    }

    this.logger.debug("Donation: ", donation);

    var result = this.httpClient.post<string>(this.apiUrl + "/Donation", donation).toPromise();

    this.logger.debug("Result: ", result);
    this.logger.context.pop();

    return result;

  }



  /**
   * Complete a non BBPS donation and save processor transaction id
   */
  async completeOnlineGivingDonation(id: string, processorTransactionId: string): Promise<boolean> {
    this.logger.context.push("CompleteOnlineGivingDonation");

    this.logger.debug("Complete donation: ", id);

    var url = `${this.apiUrl}/donation/completedonation/${id}/${processorTransactionId}`;
    var result = this.httpClient.post<boolean>(url, "").toPromise();
    // var result = this.httpClient.post<boolean>(this.apiUrl + "/donation/completedonation", params).toPromise();

    this.logger.debug("Result", result);

    this.logger.context.pop();

    return result;
  }

  /**
   * In the event of a failed transaction update the donation transaction status and failure data
   * @param id Id of failed transaction
   * @param failureData Processor response or error
   */
  async updateOnlineGivingDonationWithFailure(id: string, failureData: string): Promise<boolean> {
    this.logger.context.push("updateOnlineGivingDonationWithFailure");
    this.logger.debug("Failed Donation: ", id);
    var url = `${this.apiUrl}/donation/donationFailed/${id}/${failureData}`;
    var result = this.httpClient.post<boolean>(url, "").toPromise();

    this.logger.debug("Result", result);
    this.logger.context.pop();
    return result;
  }

  /**
   * Retrieve a donation stored in the Online Giving Donation Transactions (non BBPS donation)
   * @param id Donation ID
   */
  async getOnlineGivingDonation(id): Promise<IDonation> {
    await this.checkConfigSettings();
    this.logger.context.push("getOnlineGivingDonation | ID: ", id);

    var url = `${this.apiUrl}/donation/${id}`;

    var result = this.httpClient.get<IDonation>(url).pipe(tap(data => this.logger.debug("Result", data))).toPromise();

    this.logger.context.pop();

    return result;

  }

  /**
   * Retrieves a stored donation from BB API and attempts to re-add the country
   * and state objects before returning to the client
   * @param id Donation ID
   */
  async getDonation(id): Promise<IDonation> {
    await this.checkConfigSettings();

    this.logger.context.push("getDonation");

    var url: string = `${this.bbApiUrl}/${id}`;

    this.logger.debug("Donation API Url: ", url);

    // Get the donation
    var donation = await this.httpClient.get<IDonation>(url).pipe(map(data => {
      this.logger.debug("Donation result: ", data);
      return data;
    }),
      catchError(err => this.handleError(err))).toPromise();

    // Get list of countries
    var countries = await this.countryService.getCountries();
    this.logger.debug("Countries: ", countries);

    var promise = new Promise((resolve, reject) => {
      try {
        var donor = new Donor(Donor.getDonor(donation.Donor));
        this.logger.debug("Donor - Promise:", donor);
        donation.Donor = donor;
        this.logger.debug("Donation with donor: ", donation);
        // Find country
        var country = countries.find(x => x.Description.toLowerCase() ===
          donation.Donor.address.country.toString().toLowerCase());
        this.logger.debug("Country: ", country);
        // Get list of states
        this.countryService.getCountry(country.Id).then(data => {
          // Find state
          var state = data.states.find(x => x.Abbreviation.toLowerCase() ===
            donation.Donor.address.state.toString().toLowerCase());
          this.logger.debug("State: ", state);

          // Add state
          if (state) {
            donation.Donor.address.state = new State(state.Id, state.Description, state.Abbreviation);
          }
          else {
            donation.Donor.address.state = ConstantsService.noStateObj;
          }

          this.logger.debug("Donation with state: ", donation);

          // Add country
          donation.Donor.address.country = new Country(country.Id, country.Description, country.Abbreviation);
          this.logger.debug("Donation with country: ", donation);
          resolve(donation);
        });
      }
      catch (e) {
        this.logger.error("", e);
      }

    });

    this.logger.context.pop();

    return promise;
  }

  /**
   * DO NOT USE - Cache the donation id in web api for later retrieval
   * @param id Donation ID
   */
  async saveDonation(id: string): Promise<string> {
    // TODO: Using this currently causes a 403 error when attempting to read back a donation from donation api.
    this.logger.context.push("saveDonation");
    this.logger.debug("DonationId: ", id);
    await this.checkConfigSettings();
    var url: string = this.apiUrl;

    var donation = { "id": id };

    this.logger.debug("Donation for WebApi: ", donation);
    var result = this.httpClient.post<string>(url, donation).toPromise();
    this.logger.context.pop();
    return result;
  }

  /** Get Donation ID from web api */
  async getDonationId(): Promise<IDonationId> {
    await this.checkConfigSettings();
    return this.httpClient.get<IDonationId>(this.apiUrl).toPromise();
  }

  // TODO: Refactor acknowledgement out to its own service

  /**
   * Send an acknowledgement to the user
   * @param donation
   */
  async sendAcknowledgementWithDonation(donation: IDonation): Promise<string> {
    this.logger.context.push("sendAcknowledgement");
    this.logger.debug("Donation: ", donation);

    await this.checkConfigSettings();
    var url: string = `${this.apiUrl}/Acknowledgement`;
    var result = this.httpClient.post<string>(url, donation).pipe(tap(data => this.logger.debug("Result: ", data))).toPromise();
    this.logger.context.pop();
    return result;
  }

  /** Generate a donation object and call sendAcknowledgementWithDonation --*/
  async sendAcknowledgement(): Promise<string> {
    this.logger.context.push("sendAcknowledgement");
    let donation = await this.generateBaseDonationObject();
    donation.Donor = <IDonor>this.donor.getBbDonor();
    this.logger.debug("Donation: ", donation);
    var result = this.sendAcknowledgementWithDonation(donation);
    this.logger.context.pop();
    return result;
  }

  // TODO: Refactor all donor methods out to a new donor service

  /**
   * Store donor info for later use
   * @param donor Donor object to store
   */
  async saveDonor(donor: Donor): Promise<Donor> {

    this.logger.context.push("saveDonor");
    this.logger.debug("Donor: ", donor);
    this.logger.debug("donor.address.state: ", donor.address.state.Id);

    await this.checkConfigSettings();
    var url: string = `${this.apiUrl}/Donor`;
    this.logger.debug("Url", url);
    var result = this.httpClient.post<Donor>(url, donor).pipe(tap(data => {
      this.logger.debug("Result - SaveDonor: ", data);
    })).toPromise();
    this.logger.context.pop();
    return result;
  }

  /** Retrieve stored donor object if one exists on web api */
  async getDonor(): Promise<Donor> {
    this.logger.context.push("getDonor");
    await this.checkConfigSettings();
    var url: string = `${this.apiUrl}/Donor`;
    var result = this.httpClient.get<Donor>(url).pipe(tap(data => {
      this.logger.debug("Result: ", data);
      // If there is an organization name, set isCorporate to true
      if (data.organizationName) {
        this.isCorporate = true;
      }
    })).toPromise();
    this.logger.context.pop();
    return result;
  }

  /** Delete donor information from cache */
  async deleteDonor(): Promise<string> {
    this.logger.context.push("deleteDonor");
    await this.checkConfigSettings();
    var url: string = `${this.apiUrl}/Donor`;
    this.logger.debug("Url: ", url);
    var result = this.httpClient.delete<string>(url).pipe(tap(data => { this.logger.debug("Result: ", data) })).toPromise();
    this.logger.context.pop();
    return result;
  }

  async completeBbsDonation(id): Promise<string> {
    // TODO: What is the return type really
    this.logger.context.push("CompleteBbsDonation");
    this.logger.debug("Id: ", id);
    await this.checkConfigSettings();

    var url: string = `${this.bbApiUrl}/${id}/completebbspdonation`;
    var result = this.httpClient.post<string>(url, "").toPromise();
    this.logger.context.pop();
    return result;
  }

  async generateBaseDonationObject(): Promise<IDonation> {
    this.logger.context.push("generateBaseDonation");
    var gifts: IGift[];
    var appealId: string;

    await this.giftService.getGifts().then().then(
      data => {
        this.logger.debug("Got gifts: ", data);
        gifts = data;
      });

    this.logger.debug("Creating designations");
    var designations = [];
    gifts.forEach(g => {
      designations.push({
        "Amount": g.amount,
        "DesignationId": g.designation.designationId
      });
      // If there is an appeal id on the gift, capture it
      // There is only one appeal id per donation, so we'll take whatever one we find here
      if (g.appealId) {
        appealId = g.appealId;
      }
      this.logger.debug("AppealId: ", appealId);
    });

    // Create donation object from Donor and gifts

    var donation: IDonation = {
      // Donor: donor,
      origin: {
        pageId: 624,
        appealId: appealId
      },
      gift: {
        isCorporate: this.isCorporate,
        isAnonymous: this.isAnonymous,
        paymentMethod: 0, // Credit Card
        designations: designations,
        attributes: [
          {
            attributeId: ConstantsService.espiGiftTypeAttributeCode, // ESPI Gift Type
            value: (this.giftType === GiftType.PledgePayment) ? ConstantsService.espiGiftTypePledge : (this.giftType === GiftType.Recurring) ? ConstantsService.espiGiftTypeRecurring : ConstantsService.espiGiftTypeNewGift,
          },
          {
            attributeId: ConstantsService.espiRecurringAttributeCode, // Recurring
            value: (this.giftType === GiftType.Recurring) ? "true" : "false"
          }
        ],
      },

    };

    // Only add Recurrence object one exists
    if (this.recurrence) {
      // Workaround for startDate/dayOfMonth issue
      this.recurrence.dayOfMonth = this.recurrence.startDate.getDate();

      donation.gift.recurrence = this.recurrence;
      // If it is an annual gift set the month field
      this.logger.debug("Recurrence: ", this.recurrence);
      if (this.recurrence.frequency == RecurrenceType.Annually) { // TODO: a === comparison doesn't seem to work here
        // Per Blackbaud, if it is an annual gift, create a new date using month, day, year with no time
        var startDate = this.recurrence.startDate;

        this.recurrence.startDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
        this.logger.debug("StartDate: ", this.recurrence.startDate);
        this.logger.debug("Recurrence month: ", this.recurrence.startDate.getMonth() + 1);
        this.logger.debug("DayOfMonth:", this.recurrence.dayOfMonth);
        donation.gift.recurrence.month = this.recurrence.startDate.getMonth() + 1; // getMonth is 0 based

      }

      // Set the StartDate to localtime for Blackbaud
      var offset = this.recurrence.startDate.getTimezoneOffset();
      this.recurrence.startDate.setMinutes(this.recurrence.startDate.getMinutes() - offset);

    }

    var giftInstructions: string = "";

    // Add tribute if one was set and it is not a recurring gift
    if (this.tribute && !this.recurrence) {
      // Due to an existing issue in CRM, DELETE must be prepended to tribute description
      this.tribute.tributeDefinition.description = `**DELETE** ${this.tribute.tributeDefinition.type} ${this.tribute.tributeDefinition.firstName} ${this.tribute.tributeDefinition.lastName}`
      donation.gift.tribute = this.tribute;
      // Add acknowledgee if one was set
      if (this.acknowledgee && this.acknowledgee.firstName) {
        this.logger.debug("Acknowledgee: ", this.acknowledgee.getBbAcknowledgee());
        donation.gift.tribute.acknowledgee = this.acknowledgee.getBbAcknowledgee();

        giftInstructions = `Ack: ${this.acknowledgee.firstName} ${this.acknowledgee.lastName} ${this.acknowledgee.addressLines} ${this.acknowledgee.state.Abbreviation} ${this.acknowledgee.postalCode.replace(ConstantsService.noPostalCodeVal, "")}`;

      }
      // Add note to special instruction for gift processing when there is a tribute
      if (!this.comments) {
        this.comments = `See Tribute`;
      } else if (!this.comments.includes("See Tribute")) {
        this.comments = `See Tribute | ${this.comments}`;
      }
    }

    // Add comments as ESPI Gift Instructions attribute
    if (this.comments) {
      // Trim comments to field limit (255)
      donation.gift.comments = this.comments.substring(0, ConstantsService.attributeTextLength);
      this.logger.debug("Comments: ", this.comments);
      this.logger.debug("Trimmed Comments: ", donation.gift.comments);
      giftInstructions += `Donation Message: ${this.comments}`;
    }


    this.logger.debug("Gift Instructions: ", giftInstructions);

    if (giftInstructions) {
      // Trim gift instructions to field limit (255)
      giftInstructions = giftInstructions.substring(0, ConstantsService.attributeTextLength);
      this.logger.debug("Trimmed Gift Instructions", giftInstructions);
      donation.gift.attributes.push({ attributeId: ConstantsService.espiGiftInstructionsAttributeCode, value: giftInstructions });
    }

    // Determine merchant account to use
    // TODO: This should really not be in the base but gifts are already parsed here, so it doesn't require getting them again
    donation.merchantAccountId = this.getMerchantAccount(gifts);

    this.logger.context.pop();
    return donation;
  }

  /** Inspects the gifts and determines which merchant account to use */
  private getMerchantAccount(gifts: IGift[]): string {

    this.logger.context.push("getMerchantAccount");

    const foundationAccount:string = this.fdnMerchantAccountId;
    const regentsAccount: string = this.regMerchantAccountId;

    this.logger.debug("Foundation Account: ", foundationAccount);
    this.logger.debug("Regents Account: ", regentsAccount);

    var result: string = "";

    var foundation: boolean = false;
    var regents: boolean = false;

    // Loop through list of gifts and check if account number starts with f or r. If null default to F
    this.logger.debug("Gifts: ", gifts);

    gifts.forEach(g => {
      var category = g.designation.purposeCategory || "Foundation";
      this.logger.debug("Category: ", category);
      switch (category) {
        case "Regents":
          regents = true;
          break;
        case "Foundation": // Intentional fallthrough to default
        default:
          foundation = true;
          break;
      }
    });

    this.logger.debug("Foundation: ", foundation);
    this.logger.debug("Regents: ", regents);

    // Determine which merchant account.
    // Regents if Regents and only regents. Otherwise Foundation
    if (foundation) {
      result = foundationAccount;
    } else if (regents) {
      result = regentsAccount;
    } else {
      // Default to foundation
      result = foundationAccount;
    }

    this.logger.debug("merchantAccountId: ", result);

    this.logger.context.pop();
    return result;
  }




  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    // Throwing the error message back broke a check on return code
    // in the application so far now we will just log it here and
    // throw back the http error

    this.logger.error("Http error: ", err);
    this.logger.error(errorMessage);

    return throwError(err);
  }


}

