import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import swal from "sweetalert2";

import { IDesignation } from "@gift/designation";
import { SearchService } from "@shared/search.service";
import { LoggerService } from "@shared/logger.service";


@Component({
  selector: 'app-fund-search',
  templateUrl: './fund-search.component.html',
  styleUrls: ['./fund-search.component.css'],
  providers: [LoggerService]
})
export class FundSearchComponent implements OnInit {
  @Input() showLink: boolean = false;
  @Input() searchFor: string = "";

  @Output() searchResultSelectionChanged = new EventEmitter<IDesignation>();

  searchText: string = '';
  searchResults: IDesignation[];
  searching: boolean = false;
  noResults: boolean = false;
  selectedFund?: IDesignation = null;

  placeholderText = "Enter keyword or fund number";

  errorMessage: string;

  constructor(
    private searchService: SearchService,
    private logger: LoggerService) {

    this.logger.context.push("fund-search");
  }

  /**
   * Retrieve search results from api and set drop down with results
   * @param searchText Text to be searched calling fuzzy search on API
   */
  onSearch(searchText: string) {
    this.selectedFund = null;
    this.logger.context.push("onSearch");
    this.searching = true;
    this.noResults = false;
    this.searchResults = null;

    this.logger.debug("Search text: ", searchText);
    if (searchText.length > 0) {

      this.searchService.fuzzySearch(searchText).then(
        data => {
          this.searching = false;
          // Check for results
          if (data.length > 0) {
            this.searchResults = data;
            this.logger.debug("Search results: ", data);

            // If only one result is returned automatically select it
            if (data.length == 1){
              this.searchResultSelected(data[0]);
            }

          } else {
            this.noResults = true;
          }
        },
        error => this.errorMessage = <any>error);
    } else {
      this.logger.debug("No results found");
      swal.fire({
        title: "No Results",
        text: "Please enter a word or phrase before searching",
        icon: "warning",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-swal-confirm-button'
        }
      });

      this.searching = false;
    }
    this.logger.context.pop();
  }

  /**
   * Used to set values based on selection from search results
   * @param result
   */
  searchResultSelected(result: IDesignation) {
    if (result) {
      // Emit the selected designation for it to be picked up by parent
      this.searchResultSelectionChanged.emit(result);
      // Set description to be displayed
      this.selectedFund = result;
    } else {
      this.selectedFund = null;
    }
  }

  ngOnInit() {
    this.logger.context.push("ngOnInit");
    this.logger.debug("searchFor: ", this.searchFor);

    // If a searchFor value is passed in, search for it
    if (this.searchFor){
      this.searchText = this.searchFor;
      this.onSearch(this.searchFor);
    }

    this.logger.context.pop();
  }

}
