export interface IState {
  Id: string;
  Description: string;
  Abbreviation: string;
}

export class State implements IState {
  Id: string;
  Description: string;
  Abbreviation: string;

  /** Returns state abbreviation */
  public toString() {
    return this.Abbreviation;
  }

  constructor(id: string, description: string, abbreviation: string) {
    this.Id = id;
    this.Description = description;
    this.Abbreviation = abbreviation;
  }
}
