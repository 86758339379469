<div class="form-group" *ngIf="donationService.giftType === GiftType.Recurring && donationService.paymentType === PaymentType.CreditCard">
  <span class="less-emphasized-text">
    {{"Charge $" + total + " To My Account"}}
  </span>
  <div class="w-100">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>Frequency</mat-label>
      <mat-select id="frequency" [(value)]="donationService.recurrence.frequency">
        <mat-option *ngFor="let recurrenceOption of recurrenceOptions" [value]="recurrenceOption">
          {{RecurrenceType[recurrenceOption]}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="alert alert-danger mt-md-1" *ngIf="!donationService.recurrence.frequency && submitting">
    You must select a frequency for recurring gifts
  </div>
</div>

<form #f="ngForm">
  <div class="d-flex">
    <div class="w-100 form-group" *ngIf="donationService.giftType === GiftType.Recurring && donationService.recurrence.frequency != null && donationService.paymentType !== PaymentType.PayrollDeduction">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Start Date</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" placeholder="Start date" id="startDate" name="startDate" #startDate="ngModel" [(ngModel)]="donationService.recurrence.startDate" required />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
</form>

<div class="info-box" *ngIf="donationService.giftType === GiftType.Recurring && donationService.paymentType === PaymentType.CreditCard">
  <span id="recurringInfo" [innerHtml]="recurringInfo"></span>
</div>
<div id="payrollDeductionInfo" class="info-box" *ngIf="donationService.paymentType === PaymentType.PayrollDeduction" [innerHtml]="payrollDeductionInfo">
</div>
