import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";


import { LoggerService } from "@shared/logger.service";
import { IDesignation } from "@gift/designation";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-link-generator',
  templateUrl: './link-generator.component.html',
  styleUrls: ['./link-generator.component.css'],
  providers: [LoggerService]
})
export class LinkGeneratorComponent implements OnInit {

  addForm: UntypedFormGroup;
  errorMessage: string;

  designationName: string;

  constructor(private formBuilder: UntypedFormBuilder,
    private spinnerService: NgxSpinnerService,
    private logger: LoggerService) {

    logger.context.push("link-generator");
  }


  /**
   * Catch fund selected by search results or fund selector
   * @param selectedFund
   */
  onFundSelected(selectedFund: IDesignation) {
    this.fundId.patchValue(selectedFund);
    this.designationName = selectedFund.publicName;
  }

  get fundId() { return this.addForm.get("fundId"); }

  ngOnInit() {

    this.spinnerService.hide();

    // Create form
    this.addForm = this.formBuilder.group({
      area: [],
      division: [],
      fundId: ["", Validators.required]
    });

  }

}
