import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from "@angular/router";

import swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

import { GiftService } from "../../gift.service";
import { IGift } from "../../gift";
import { LoggerService } from "../../../shared/logger.service";
import { HelperService } from "../../../shared/helper.service";

@Component({
  selector: 'app-gift-list',
  templateUrl: './gift-list.component.html',
  styleUrls: ['./gift-list.component.css']
})
export class GiftListComponent implements OnInit {

  @Output() totalChanged = new EventEmitter<number>();

  pageTitle: string = 'You Have Selected The Following Gifts:';
  
  gifts: IGift[];
  total: number;
  errorMessage: string;

  constructor(private giftService: GiftService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private logger: LoggerService) {

    this.logger.context.push("gift-list");
  }


  /**
 * Route to Add Gift component
 */
  addGift(): void {
    this.router.navigateByUrl("/add-gift");
    HelperService.goToTop();
  }

  /**
   * Calls the deleteGift method on the gift service and attempts to delete the gift based on id provided
   * @param giftId Id of the gift that should be deleted
   */
  onDeleteGift(giftId: number): void {
    this.logger.context.push("onDeleteGift");
    this.giftService.deleteGift(giftId).subscribe(
      data => {
        this.logger.debug(`GiftId: ${data} deleted`);
        swal.fire({
          title: "Deleted",
          text: "",
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.getGifts();
      });
    this.logger.context.pop();
  }


  /**
   * Navigate to Edit component based on id provided
   * @param giftId Id of the gift that should be edited
   */
  onEditGift(giftId: number): void {
    this.router.navigate(["/edit-gift", giftId]);
    HelperService.goToTop();
  }


  /**
   * Attempts to retrieve cached gifts. If no cached gifts exists it will route to the AddGift component
   */
  getGifts(): void {
    this.logger.context.push("getGifts");
    this.giftService.getGifts().then(
      data => {
        if (data.length >= 1) {
          // TODO: get rid of duplicate storage
          this.logger.debug("Gifts: ", data);
          this.gifts = data;
          this.getTotal();
          this.giftService.gifts = data;
          this.spinnerService.hide();
        } else {
          this.router.navigateByUrl("/add-gift");
        }
      },
      error => this.errorMessage = <any>error);
    this.logger.context.pop();
  }


  /**
   * Sums the Amount of all cached gifts and returns the total
   */
  getTotal(): void {
    var total: number = 0;
    this.gifts.forEach(a => {
      total += a.amount;
    });
    this.total = total;
    this.totalChanged.emit(total);
  }

  /**
   * Cancel of delete   
   */
  onCancel(event): void {
    this.logger.debug("Delete gift cancelled");
    swal.fire({
      title: "Cancelled",
      text: "",
      icon: "info",
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-swal-confirm-button'
      }
    });
  }

  ngOnInit() {
    this.getGifts();

  }
}

