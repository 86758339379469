import { IAddress, Address, IBbAddress } from "./address";
import { TitleCasePipe } from "@angular/common";

export interface IDonor {
  FirstName: string;
  lastName: string;
  organizationName?: string;
  emailAddress?: string;
  phone?: string;
  address: Address;
}

export interface IBbDonor {
  FirstName: string;
  LastName: string;
  OrganizationName?: string;
  EmailAddress: string;
  Phone: string;
  Address: IBbAddress;
}

export class Donor implements IDonor {
  FirstName: string;
  lastName: string;
  organizationName: string;
  emailAddress: string;
  phone: string;
  address: Address;
  

  public constructor(init?: Partial<Donor>) {
    Object.assign(this, init);
  }

  // TODO: Data being sent to BB is in proper case, but it would be nice if we could enforce it on the UI side as well

  // TODO: This class needs refactored after handling retrieving a Donation from the BB API

  /**
   * Returns a copy of Donor object using Blackbaud fields
   */
  public getBbDonor(): Object {
    var address = {
      country: this.address.country.Description,
      state: this.address.state.Abbreviation,
      streetAddress: new TitleCasePipe().transform(this.address.addressLines),
      city: new TitleCasePipe().transform(this.address.city),
      postalCode: this.address.postalCode
    }

    var result = {
      FirstName: new TitleCasePipe().transform(this.FirstName),
      lastName: new TitleCasePipe().transform(this.lastName),
      organizationName: new TitleCasePipe().transform(this.organizationName),
      emailAddress: this.emailAddress,
      phone: this.phone,
      address: address
    }
    return result;
  }

  // TODO: Need to create a new class for BBDonor object

  /**
   * Takes a BBDonor object and converts it to a local Donor object
   * @param donor BBDonor object
   */
  public static getDonor(donor): Object {
    var address = {
      country: new TitleCasePipe().transform(donor.Address.Country),
      state: donor.Address.State,
      addressLines: new TitleCasePipe().transform(donor.Address.StreetAddress),
      city: new TitleCasePipe().transform(donor.Address.City),
      postalCode: donor.Address.PostalCode
    }

    var result = {
      FirstName: new TitleCasePipe().transform(donor.FirstName),
      lastName: new TitleCasePipe().transform(donor.LastName),
      organizationName: new TitleCasePipe().transform(donor.OrganizationName),
      emailAddress: donor.EmailAddress,
      phone: donor.Phone,
      address: address
    }
    return result;
  }
  

}


