import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { IGift } from "../gift/gift";
import { GiftService } from "../gift/gift.service";
import { SsoUserService } from "../shared/sso-user/sso-user.service";
import { ISsoUser } from "../shared/sso-user/sso-user";
import swal from "sweetalert2";

import { Event as NavigationEvent } from "@angular/router";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";
import { DonationService } from "../gift/donation/donation.service";
import { PayrollDeductionService } from "./payroll-deduction.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "../shared/constants.service";
import { LoggerService } from "../shared/logger.service";
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-payroll-deduction',
  templateUrl: './payroll-deduction.component.html',
  styleUrls: ['./payroll-deduction.component.css'],
  providers: [LoggerService]
})
export class PayrollDeductionComponent implements OnInit {
  readonly acknowledgementText: string = "UC San Diego will contact me if there are any questions about my contribution. My payment will be reflected on my UC San Diego Payroll stub and will satisfy the Internal Revenue Service Regulations regarding charitable gifts.";
  readonly payrollNotice: string = "Please be aware that, at this time, payroll deductions are ongoing, recurring gifts. To make a one-time gift, please give online using your credit/debit card or give us a call directly at (858) 534-1610";

  pageTitle: string = "Payroll Deduction";
  
  giftTotal: number = 0;
  isAcknowledged: boolean = false;
  // ssoUser: ISsoUser;
  payrollCycle: string;

  submitting: boolean = false;

  @ViewChild("f", { static: true }) form: NgForm;

  attempts: number = 0;

  constructor(public giftService: GiftService,
    private ssoUserService: SsoUserService,
    public donationService: DonationService,
    private payrollDeductionService: PayrollDeductionService,
    private router: Router,
    private spinnerService: NgxSpinnerService,
    private logger: LoggerService,
    private titleService: Title) {

      this.titleService.setTitle(`${ConstantsService.pageTitle} Your Info`);

    this.logger.context.push("payroll-deduction");
    router.events.pipe(
      filter(
        (event: NavigationEvent) => {
          return (event instanceof NavigationStart);
        }
      )
    )
      .subscribe(
        (event: NavigationStart) => {

          if (event.restoredState) {
            //console.warn("restoring navigation id:", event.restoredState.navigationId);
            // Navigate back to the review page as back will not work with SSO
            router.navigateByUrl("/review");
          }

      }
    );
  }

  /** Validate form and submit to gift processing */
  submit(): void {
    this.logger.context.push("submit");
    this.submitting = true;

    // Check for sso user
    if (!this.donationService.ssoUser || !this.donationService.ssoUser.employeeId) {
      swal.fire({
        title: "An Error Occurred",
        text: "Your employee id could not be retrieved from single sign on. Please return to the previous page and try again, or contact Gift Processing to continue",
        icon: "error",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-swal-confirm-button'
        }
      });
      return;
    }

    // Validate form
    if (this.form.invalid) {
      this.attempts++;
      return;
    }

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);
    
    this.payrollDeductionService.addPayrollDeduction(this.payrollCycle).then(
      data => {
        this.logger.debug("Id: ", data);
        this.router.navigateByUrl("payrollsubmitted");
      }, error => {
        this.spinnerService.hide();
        swal.fire({
          title: "An Error Occurred",
          text: ConstantsService.processingErrorMessage,
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.logger.fatal("An error occurred while attempting to add a Payroll Deduction", error);
      });
    
    this.logger.context.pop();
  }

 

  // TODO: refactor this out to the service or a helper
  getTotal(): number {
    this.logger.context.push("getTotal");
    // TODO: Why is this getting called so many times???
    if (this.giftTotal === 0) {
      var total: number = 0;
      if (this.giftService.gifts) {
        this.giftService.gifts.forEach(a => {
          total += a.amount;
        });
      }
      this.logger.debug("Total: ", total);
      this.giftTotal = total;
    }
    this.logger.context.pop();
    return this.giftTotal;
  }
  
  getGifts(): void {
    this.logger.context.push("getGifts");
    this.giftService.getGifts().then(
      data => {
        this.giftService.gifts = data;
        this.logger.debug("Gifts: ", this.giftService.gifts);
        this.getTotal();
      });
    this.logger.context.pop();
  }

  ngOnInit() {
    this.ssoUserService.getSsoUser().then(
      data => {
        this.donationService.ssoUser = data;
      });

    this.getGifts();

  }
}

