import { Component, OnInit, Input, OnChanges, SimpleChange, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";

import { GiftType } from "../../donation/gift-type.enum";
import { PaymentType } from "../../donation/payment-type.enum";
import { DonationService } from "../../donation/donation.service";
import { RecurrenceType } from "../../donation/recurrence-type.enum";

import { LoggerService } from "../../../shared/logger.service";
import { HelperService } from "../../../shared/helper.service";

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.css'],
  providers: [LoggerService]
})
export class RecurrenceComponent implements OnInit, OnChanges {

  @Input() total: number;
  @Input() recurring: boolean;

  readonly recurringInfo: string = 'Thank you for becoming a sustaining donor! Your gift will be charged to the credit/debit card you provide in the amount and frequency indicated above. You may change or cancel your sustaining gift at any time by contacting Gift Processing at <a href="mailto:onlinegift@ucsd.edu">onlinegift@ucsd.edu</a>'
  readonly payrollDeductionInfo: string = "The Payroll Deduction option is for qualified UC San Diego employees only. You will be prompted to use your Single Sign On for this payment option.<br/><br/>New recurring gifts via credit card are not active at this time. Please email <a href='mailto:giving@ucsd.edu'>giving@ucsd.edu</a> if you would like to be notified when this option is available.";

  GiftType = GiftType;
  PaymentType = PaymentType;
  RecurrenceType = RecurrenceType;

  submitting: boolean = false;

  recurrenceOptions: string[];

  minDate: Date = new Date();

  @ViewChild("f", { static: true }) form: NgForm;

  constructor(public donationService: DonationService,
    private logger: LoggerService) {

    this.logger.context.push("recurrence");

    if (!this.donationService.recurrence) {

      this.donationService.recurrence = { startDate: new Date()}
      this.logger.debug("Recurrence created: ", this.donationService.recurrence);
    }
    this.recurrenceOptions = Object.keys(this.RecurrenceType).filter(f => !isNaN(Number(f)));
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {
    this.logger.context.push("ngOnChanges");
    for (let propName in changes) {
      let changedProp = changes[propName];
      if (propName === "recurring" && !changedProp.currentValue) {
        this.donationService.recurrence = null;
      } else {
        if (!this.donationService.recurrence) {
          this.donationService.recurrence = { startDate:  new Date() };
        }
      }
    }
    this.logger.debug("Recurrence updated: ", this.donationService.recurrence);
    this.logger.context.pop();
  }


  ngOnInit() {
  }

}
