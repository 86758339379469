import { IState } from "../../shared/state"
import { ICountry } from "../../shared/country";
import { TitleCasePipe } from "@angular/common";

export interface IBbAcknowledgee {
  firstName?: string;
  lastName?: string;
  country?: ICountry;
  addressLines?: string;
  city?: string;
  state?: IState;
  postalCode?: string;
  phone?: string;
  email?: string;
}


export class Acknowledgee implements IBbAcknowledgee {
  firstName: string;
  lastName: string;
  country: ICountry;
  addressLines: string;
  city: string;
  state: IState;
  postalCode: string;
  phone: string;
  email: string;

  public constructor(init?: Partial<Acknowledgee>) {
    Object.assign(this, init);
  }

  public getBbAcknowledgee(): Object {
    var result = {
      firstName: new TitleCasePipe().transform(this.firstName),
      lastName: new TitleCasePipe().transform(this.lastName),
      addressLines: new TitleCasePipe().transform(this.addressLines),
      city: new TitleCasePipe().transform(this.city),
      state: this.state.Abbreviation,
      country: this.country.Description,
      postalcode: this.postalCode,
    }
    return result;
  }

}

