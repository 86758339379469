import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { AppConfigService } from "../app-config.service";
import { IGift } from "./gift";
import { LoggerService } from "../shared/logger.service";

@Injectable({
    providedIn: 'root'
})
export class GiftService {
    private apiUrl: string;
    private httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    };

    constructor(private http: HttpClient,
        private appConfigService: AppConfigService,
        private logger: LoggerService) {

        this.logger.context.push("gift-service");
    }

    private _gifts: IGift[];
    get gifts(): IGift[] {
        return this._gifts;
    }
    set gifts(value: IGift[]) {
        this._gifts = value;
    }


    async checkConfigSettings() {
        // TODO: Factor this out into a shared class that can be called from other services

        this.logger.context.push("checkConfigSettings");
        // Check if settings has alreaby been set
        if (AppConfigService.settings == null) {
            // If not, load it now
            AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
            this.logger.info("Config settings loaded: ", AppConfigService.settings);
        }
        // Use data to update local variables
        this.apiUrl = AppConfigService.settings.webApi.url + "/gift";
        this.logger.debug(`apiUrl: ${this.apiUrl}`);
        this.logger.context.pop();
    }


    async getGifts(): Promise<IGift[]> {
        this.logger.context.push("getGifts");
        await this.checkConfigSettings();
        var results = this.http.get<IGift[]>(this.apiUrl, this.httpOptions).pipe(tap(data => this.logger.debug("Gifts:", data))).toPromise();
        this.logger.context.pop();
        return results;
    }

    async getGift(id: number): Promise<IGift> {
        this.logger.context.push("getGift");
        await this.checkConfigSettings();
        const options = { params: new HttpParams().set('id', id.toString()) }

        var results = this.http.get<IGift>(this.apiUrl, options).pipe(tap(data => this.logger.debug("Gift: ", data))).toPromise();
        this.logger.context.pop();
        return results;
    }

    async addGift(gift: IGift): Promise<number> {
        this.logger.context.push("addGift");
        this.logger.debug("Gift: ", gift);
        await this.checkConfigSettings();
        var result = this.http.post<number>(this.apiUrl, gift, this.httpOptions).pipe(tap(data => this.logger.debug("Result: ", data))).toPromise();
        this.logger.context.pop();
        return result;
    }

    deleteGift(id: number): Observable<number> {
        this.logger.context.push("deleteGift");
        this.logger.debug(`id: ${id}`);
        const options = { params: new HttpParams().set('id', id.toString()) };

        var result = this.http.delete<number>(this.apiUrl, options).pipe(tap(data => this.logger.debug("Result: ", data)));
        this.logger.context.pop();
        return result;
    }

    async editGift(gift: IGift): Promise<number> {
        this.logger.context.push("editGift");
        this.logger.debug("Gift: ", gift);

        this.logger.debug("Url: ", this.apiUrl);

        var result = await this.http.put<number>(this.apiUrl, gift, this.httpOptions).pipe(tap(data => {
            this.logger.debug("Result: ", data);
          }),
          catchError(err => this.handleError(err))).toPromise();
        
        this.logger.context.pop();
        return result;
    }


    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';

        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }

        this.logger.error("Http error: ", err);
        this.logger.error(errorMessage);

        return throwError(err);
    }

}
