import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { YourInfoComponent } from "./your-info/your-info.component";
import { GiftListComponent } from "./gift/gift-review/gift-list/gift-list.component";
import { GiftReviewComponent } from "./gift/gift-review/gift-review.component";
import { GiftAddComponent } from "./gift/gift-add/gift-add.component";
import { ConfirmationComponent } from "./gift/donation/confirmation/confirmation.component";
import { PayrollDeductionComponent } from "./payroll-deduction/payroll-deduction.component";
import { MatchingGiftComponent } from "./matching-gift/matching-gift.component";
import { GiftAddReferralComponent } from "./gift/gift-add/gift-add-referral/gift-add-referral.component";
import { GiftEditComponent } from "./gift/gift-add/gift-edit/gift-edit.component";
import { LinkGeneratorComponent } from "./gift/gift-add/link-generator/link-generator.component";

const routes: Routes = [
  { path: "confirmation/:t", component: ConfirmationComponent },
  { path: "confirmation", component: ConfirmationComponent },
  { path: "payrollsubmitted", component: ConfirmationComponent },
  { path: 'payrollconfirmation', component: PayrollDeductionComponent },
  { path: 'review', component: GiftReviewComponent },
  { path: 'your-info', component: YourInfoComponent },
  { path: "edit-gift/:id", component: GiftEditComponent },
  { path: "add-gift/:appealId", component: GiftAddComponent },
  { path: "add-gift", component: GiftAddComponent },
  { path: "gift-referral/:id/:appealId", component: GiftAddReferralComponent },
  { path: "gift-referral/:id", component: GiftAddReferralComponent },
  { path: "matching-gift", component: MatchingGiftComponent },
  { path: "link-generator", component: LinkGeneratorComponent },
  { path: '', component: GiftReviewComponent },
  { path: '**', component: GiftReviewComponent } 
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
