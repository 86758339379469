import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { AppConfigService } from "../app-config.service";
import { IDesignation } from "@gift/designation";
import { LoggerService } from "@shared/logger.service";


@Injectable({
  providedIn: 'root'
})
export class DesignationService {
  private designationApiUrl: string; // = AppConfigService.settings.webApi.url + "/designation";
  private fundApiUrl: string; // = AppConfigService.settings.webApi.url + "/fund";

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService,
    private logger: LoggerService) {

    this.logger.context.push("designation-service");
  }


  async checkConfigSettings() {
    this.logger.context.push("checkConfigSettings");
    // TODO: Factor this out into a shared class that can be called from other services
    // Check if settings has already been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
      this.logger.info("Config settings loaded: ", AppConfigService.settings);
    }
    // Use data to update local variables
    this.designationApiUrl = AppConfigService.settings.webApi.url + "/designation";
    this.fundApiUrl = AppConfigService.settings.webApi.url + "/fund";

    this.logger.context.pop();
  }

  async getDesignation(id: string): Promise<IDesignation> {
    this.logger.context.push("getDesignation");
    await this.checkConfigSettings();
    this.logger.debug("Id: ", id);
    const options = { params: new HttpParams().set("id", id) };
    var result = this.http.get<IDesignation>(this.designationApiUrl, options).toPromise();

    this.logger.context.pop();
    return result;
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error("Http error: ", err);
    return throwError(errorMessage);
  }

}
