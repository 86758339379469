import { NgModule } from '@angular/core';
import { GiftAmountComponent } from "./gift-add/gift-amount/gift-amount.component";
import { GiftAddComponent } from "./gift-add/gift-add.component";
import { GiftListComponent } from "./gift-review/gift-list/gift-list.component";
import { SharedModule } from "@shared/shared.module";
import { FundSearchComponent } from './gift-add/fund-selection/fund-search/fund-search.component';
import { FundDescriptionComponent } from './fund-description/fund-description.component';
import { GiftAddReferralComponent } from './gift-add/gift-add-referral/gift-add-referral.component';
import { SelectGiftTypeComponent } from './gift-review/select-gift-type/select-gift-type.component';
import { SelectPaymentTypeComponent } from './gift-review/select-payment-type/select-payment-type.component';
import { RecurrenceComponent } from './gift-review/recurrence/recurrence.component';
import { TributeComponent } from './gift-review/tribute/tribute.component';
import { AcknowledgeeComponent } from './gift-review/acknowledgee/acknowledgee.component';
import { GiftReviewComponent } from './gift-review/gift-review.component';
import { GiftEditComponent } from './gift-add/gift-edit/gift-edit.component';
import { LinkGeneratorComponent } from "./gift-add/link-generator/link-generator.component";
import { AppealCodeComponent } from './gift-add/link-generator/appeal-code/appeal-code.component';
import { DirectLinkComponent } from './gift-add/link-generator/direct-link/direct-link.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    GiftAmountComponent,
    GiftAddComponent,
    GiftListComponent,
    FundSearchComponent,
    FundDescriptionComponent,
    GiftAddReferralComponent,
    SelectGiftTypeComponent,
    SelectPaymentTypeComponent,
    RecurrenceComponent,
    TributeComponent,
    AcknowledgeeComponent,
    GiftReviewComponent,
    GiftEditComponent,
    LinkGeneratorComponent,
    AppealCodeComponent,
    DirectLinkComponent
  ]
})
export class GiftModule { }
