<div class="form-group" [formGroup]="form">
  <div class="d-lg-flex">
    <div class="w-100 form-group">
      <mat-form-field class="w-100 pr-lg-2 mt-4" appearance="outline">
        <mat-label>Country</mat-label>
        <mat-select id="selectCountry" [formControl]="countryCtrl" #selectCountry>
          <ngx-mat-select-search [formControl]="countryFilterCtrl" [placeholderLabel]="'Find a country...'"></ngx-mat-select-search>
          <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
            {{country.Description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="d-lg-flex">
    <div class="w-100 form-group">
      <mat-form-field class="w-100 pr-lg-2" appearance="outline">
        <mat-label>{{ addressLinesPlaceholder }}</mat-label>
        <input matInput formControlName="addressLines" name="address" id="address" />
        <mat-error *ngIf="addressLines.errors?.required">{{ addressLinesPlaceholder }} is required</mat-error>
        <mat-error *ngIf="addressLines.errors?.maxlength">{{ constants.maxLengthMessage }} is {{ constants.addressLinesCharacterLimit }} characters</mat-error>
      </mat-form-field>

    </div>
  </div>

  <div class="d-lg-flex">
    <div class="w-100 form-group">
      <mat-form-field class="w-100 pr-lg-2" appearance="outline">
        <mat-label>{{ cityPlaceholder }}</mat-label>
        <input matInput formControlName="city" name="city" id="city" />
        <mat-error *ngIf="city.errors?.required">City is required</mat-error>
        <mat-error *ngIf="city.errors?.maxlength">{{ constants.maxLengthMessage }} is {{ constants.cityCharacterLimit }} characters</mat-error>
        <mat-error *ngIf="city.hasError('invalidCharacters')">{{constants.invalidCharactersMessage}}</mat-error>
      </mat-form-field>
    </div>

    <div class="w-100 form-group" *ngIf="showState">
      <mat-form-field class="w-100 pr-lg-2" appearance="outline">
        <mat-label>{{ statePlaceholder }}</mat-label>
        <mat-select id="selectState" [formControl]="stateCtrl" #selectState >
          <ngx-mat-select-search [formControl]="stateFilterCtrl" [placeholderLabel]="'Find a ' + statePlaceholder + '...'"></ngx-mat-select-search>
          <mat-option *ngFor="let state of filteredStates | async" [value]="state">
            {{state.Description}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="" *ngIf="showPostalCode && showAddressCaption">
    <div class="w-100 form-group">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>{{ zipPlaceholder }}</mat-label>
        <input matInput formControlName="postalCode" name="zip" id="zip" />
        <mat-error *ngIf="postalCode.errors?.required">{{ zipPlaceholder }} is required</mat-error>
      </mat-form-field>
    </div>
  </div>
</div>
