import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from "ngx-logger";
import { AppConfigService } from "../app-config.service";
import { IAppConfig } from "../app-config";


@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private apiUrl: string;
  private isSet: boolean = false;
  
  constructor(private logger: NGXLogger,
    private appConfigService: AppConfigService) {
    
    this.context.push("OnlineGivingSite");

    this.prepLogger();
  }

  private async prepLogger() {
    if (AppConfigService.settings == null) {
      await this.appConfigService.loadSettings().toPromise().then(
        data => {
          this.updateLoggerSettings(data);
        });
    } else {
      this.updateLoggerSettings(AppConfigService.settings);
    }
  }

  private updateLoggerSettings(settings: IAppConfig) {
    var localLogLevel: NgxLoggerLevel = NgxLoggerLevel.DEBUG;
    var serverLogLevel: NgxLoggerLevel = NgxLoggerLevel.DEBUG;

    localLogLevel = NgxLoggerLevel[settings.logging.localLogLevel];
    serverLogLevel = NgxLoggerLevel[settings.logging.serverLogLevel];
    this.apiUrl = settings.logging.logServerUrl;

    // Ger current config and check it against settings before updating
    var config = this.logger.getConfigSnapshot();
    if (config.serverLogLevel !== serverLogLevel ||
      config.level !== localLogLevel ||
      config.serverLoggingUrl !== this.apiUrl) {

      this.logger.info("Update Logger settings");
      this.logger.updateConfig({
        level: localLogLevel,
        serverLogLevel: serverLogLevel,
        serverLoggingUrl: this.apiUrl
      });

      this.isSet = true;

      this.logger.info(`Local Log Level: ${localLogLevel}`);
      this.logger.info(`Server Log Level: ${serverLogLevel}`);
      this.logger.info(`Log API: ${this.apiUrl}`);
    } else {
      this.logger.info("No changes to logger detected");
    }
    
  }

  public async loggerReady() {
    var promise = new Promise(async(resolve, reject) => {
      
      this.logger.info(`Logger Ready: ${this.isSet}`);
      // If the logger is not setup yet attempt to set it up now
      if (!this.isSet) {
        await this.prepLogger();
        this.logger.info(`Logger Ready: ${this.isSet}`);
        resolve(this.isSet);
      } else {
        resolve(this.isSet);
      }
    });
    return promise;
  }

  // TODO: Find a way to determine when a context goes out of scope and automatically pop it

  private _context: string[] = [];
  /**
   * Context should be used to provide debugging "Context" to logs
   * It is an array of context strings which should be "Pushed" and "Popped" when
   * entering a new Context such as a method or class
   * @returns {} 
   */
  public get context(): string[] { return this._context; }

  /**
   * Creates a string out of the current context array and the message to be logged
   * @param msg Message to be logged
   */
  private getMessage(msg: string, obj?: any): string {
    // TODO: Find a way to include object even if null/undefined if it is actually passed vs just not passed
    var result = `(${this.context.join(" - ")}) ${msg} ${(obj) ? JSON.stringify(obj): ""}`;
    
    return result;
  }

  debug(msg: string, obj?: any): void {
    this.logger.debug(this.getMessage(msg, obj));
  }

  
  info(msg: string, obj?: any): void {
    this.logger.info(this.getMessage(msg, obj));
  }

  error(msg: string, obj?: any): void {
    this.logger.error(this.getMessage(msg, obj));
  }

  fatal(msg: string, obj?: any): void {
    this.logger.fatal(this.getMessage(msg, obj));
  }


}
