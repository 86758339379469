import { Component, OnInit } from '@angular/core';
import { DonationService } from "../donation.service";
import { ActivatedRoute } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { Event as NavigationEvent } from "@angular/router";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";

import { GiftService } from "../../gift.service";
import { IGift } from "../../gift";
import { IDonor, Donor } from "../donor";
import { PaymentType } from "../payment-type.enum";
import { NgxSpinnerService } from "ngx-spinner";
import swal from "sweetalert2";
import { LoggerService } from "../../../shared/logger.service";
import { ConstantsService } from "../../../shared/constants.service";
import { Title } from '@angular/platform-browser';

@
    Component({
        selector: 'app-confirmation',
        templateUrl: './confirmation.component.html',
        styleUrls: ['./confirmation.component.css'],
        providers: [LoggerService]
    })
export class ConfirmationComponent implements OnInit {
    pageTitle: string = "Confirmation";
    loadingMsg: string = "Please wait while your confirmation loads...";
    errorMessage: string;

    showConfirmation: boolean = false;

    gifts: IGift[];
    firstName: string;

    constructor(public donationService: DonationService,
        private route: ActivatedRoute,
        private giftService: GiftService,
        private spinnerService: NgxSpinnerService,
        private router: Router,
        private logger: LoggerService,
        private titleService: Title) {

        this.titleService.setTitle(`${ConstantsService.pageTitle} Complete`);

        this.logger.context.push("confirmation");
        
        router.events.pipe(
            filter(
                (event: NavigationEvent) => {
                    return (event instanceof NavigationStart);
                }
            )
        )
            .subscribe(
                (event: NavigationStart) => {

                    if (event.restoredState) {
                        this.logger.debug("Restoring navigation id: ", event.restoredState.navigationId);
                        // Navigate back to the review page rather than to the prior page (checkout or sso)
                        router.navigateByUrl("/review");
                    }

                }
            );
    }


    clearGifts(): void {
        this.logger.context.push("clearGifts");
        // Retrieve all existing gifts
        var gifts: IGift[];
        this.giftService.getGifts().then(
            data => {
                gifts = data;
                gifts.forEach(g => {
                    // Delete each gift
                    this.logger.debug("Deleting gift: ", g.designation);
                    this.giftService.deleteGift(g.giftId).subscribe(
                        data => {
                            // do something here?
                        });
                });
            });
        this.logger.context.pop();
    }

  ngOnInit() {
    
        this.logger.context.push("ngOnInit");
        const param = this.route.snapshot.paramMap.get("t");

        this.logger.debug("t: ", param);

        // Get first name from either SSO user or Donor
        if (this.donationService.ssoUser) {
            this.logger.debug("Sso user found");
            var ssoUser = this.donationService.ssoUser;
            this.logger.debug("ssoUser: ", ssoUser);
            this.firstName = ssoUser.firstName;

            // Create donor from sso user
            let donor: IDonor = {
                FirstName: ssoUser.firstName,
                lastName: ssoUser.lastName,
                organizationName: "",
                emailAddress: ssoUser.email,
                phone: ssoUser.workPhone,
                address: {
                    country: {
                        Id: "", Description: "", Abbreviation: ""
                    },
                    state: { Id: "", Description: "", Abbreviation: "" },
                    city: "",
                    addressLines: "",
                    postalCode: ""
                }
            }
            this.logger.debug("Donor: ", donor);
            this.donationService.donor = new Donor(donor);

            // Send acknowledgement
            this.donationService.sendAcknowledgement();

            this.showConfirmation = true;
            this.spinnerService.hide();
            this.logger.info("Payment complete");
            // Clear the gifts
            this.clearGifts();
        } else if (param) {
          // If this is not an SSO(payroll deduction) attempt to get the donation object and complete the donation
          this.logger.debug("Not payroll deduction. Retrieving donation from BB...");
          this.donationService.getDonation(param).then(
            data => {
              // TODO: confirm that an object was received
              this.logger.debug("Retrieved donation: ", data);
              this.firstName = data.Donor.FirstName;

              this.logger.debug("Attempt to complete bb donation");
              this.donationService.completeBbsDonation(param).then(
                paymentCompleteData => {
                  this.logger.debug("Payment complete: ", paymentCompleteData);

                  this.showConfirmation = true;
                  this.spinnerService.hide();
                  this.logger.info("Payment complete");

                  // Send acknowledgement 
                  this.donationService.sendAcknowledgementWithDonation(data);

                  // Clear the gifts
                  this.clearGifts();

                  // Clear donor info
                  this.donationService.deleteDonor();
                },
                error => {
                  this.logger.error("", error);
                  var errorCode = error.error[0].ErrorCode;
                  this.logger.debug("Error code: ", errorCode);
                  if (errorCode === 203 || errorCode === 403) {
                    // BB Payment was not completed or cannot be accessed. Display message and redirect to About You page
                                swal.fire({
                                  title: "Incomplete Payment",
                                  text: "Your payment was not completed. Please try again.",
                                  icon: "warning",
                                  buttonsStyling: false,
                                  customClass: {
                                    confirmButton: 'btn btn-swal-confirm-button'
                                  }
                                });
                                
                    this.router.navigateByUrl("/your-info");
                  }
                });
            },
            err => {
              // TODO: message and reroute if we can't access the gift that they just made?
              this.logger.error("", err);
            });

        } else {
          // If there is no id see if it is a non bbps payment and lookup information locally
          this.donationService.getDonor().then(
            donor => {
              this.firstName = donor.FirstName;

              this.showConfirmation = true;
              this.spinnerService.hide();

              
              // Retrieve donation information to send acknowledgement
              this.donationService.getOnlineGivingDonation(this.donationService.donationId)
                .then(donationData => {
                  // Send acknowledgement
                  this.donationService.sendAcknowledgementWithDonation(donationData);

                  // Clear the gifts
                  this.clearGifts();

                  // Clear donor info
                  this.donationService.deleteDonor();
                });

              
            });

        }

        // Gifts
        this.logger.debug("Retrieving gifts...");
        this.giftService.getGifts().then(
            data => {
                this.gifts = data;
                this.logger.debug("Gifts: ", data);
            });

        this.logger.context.pop();
    
    }

}
