import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

import { GiftService } from "../../gift.service";
import { IGift } from "../../gift";
import { IDesignation } from "@gift/designation";
import { LoggerService } from "@shared/logger.service";
import { HelperService } from "@shared/helper.service";
import { DesignationService } from "../../designation.service";
import { ConstantsService } from "@shared/constants.service";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-gift-edit',
  templateUrl: './gift-edit.component.html',
  styleUrls: ['./gift-edit.component.css'],
  providers: [LoggerService]
})
export class GiftEditComponent implements OnInit {

  editForm: UntypedFormGroup;
  gift: IGift;
  errorMessage: string;
  designationName: string;
  queryId: string;
  amount: number;
  fundNumber: string;

  submitting: boolean = false;
  attempts: number = 0;

  constructor(private formBuilder: UntypedFormBuilder,
    private giftService: GiftService,
    private router: Router,
    private route: ActivatedRoute,
    private logger: LoggerService,
    private designationService: DesignationService,
    private spinnerService: NgxSpinnerService,
    private titleService: Title) {
      this.titleService.setTitle(`${ConstantsService.pageTitle} Gift Details`);
      this.logger.context.push("gift-edit");
  }

  /**
 * Cancel and return to the Review page
 */
  onCancel() {
    this.router.navigateByUrl("/review");
    HelperService.goToTop();
  }


  /**
   * Attempt to add the gift to the cache
   */
  onSubmit() {
    this.logger.context.push("onSubmit");
    this.submitting = true;

    // Check if form is valid
    if (!this.editForm.valid) {
      // Stop submission if form is not valid and increment counter
      this.attempts++;
      this.logger.debug("Form invalid. Attempts: ", this.attempts);
      return;
    }

    //var gift: IGift = this.addForm.value;
    let gift: IGift = { amount: +this.editForm.get("amount").value, giftId: +this.editForm.get("giftId").value };
    let designation: IDesignation = <IDesignation>this.editForm.get("fundId").value;
    gift.designation = designation;

    this.logger.debug("Designation: ", designation);

    this.giftService.editGift(gift).then(
      data => {
        this.logger.debug("Gift added: ", data);
        swal.fire({
          title: "Updated!",
          text: `Your gift for $${gift.amount} to ${this.designationName} has been saved`,
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.router.navigateByUrl("/review");
        HelperService.goToTop();
      });
    this.logger.context.pop();
  }

  /*** Nested component event listeners ***/

  /**
   * Catch amount changed event emitted by child component and set local amount value
   * @param amount value sent out by child form
   */
  onAmountChanged(amount: number) {
    this.logger.context.push("onAmountChanged");
    this.logger.debug("Amount: ", amount);
    this.editForm.patchValue({ amount: amount });
    this.logger.context.pop();
  }

  /**
   * Catch fund selected by search results or fund selector
   * @param selectedFund
   */
  onFundSelected(selectedFund: IDesignation) {
    this.fundId.setValue(selectedFund);
    this.designationName = selectedFund.publicName;
  }

  ngOnInit() {
    this.logger.context.push("ngOnInit");

    // Create form
    this.editForm = this.formBuilder.group({
      giftId: [],
      amount: ["", [Validators.required, Validators.min(1), Validators.max(99999)]],
      fundId: ["", Validators.required]
    });

    // Check url for parameter and load gift/fund if there
    const id = this.route.snapshot.paramMap.get('id');
    this.logger.debug("Id: ", id);
    if (id) {
     // Retrieve gift
       this.giftService.getGift(+id).then(
         data => {
           // Set fund number
           this.fundNumber = data.designation.accountNumber;
           // Set form values
           this.setFormValues(data);
           this.logger.debug("Gift: ", data);
         },
         error => this.errorMessage = <any>error);
    }
    this.logger.context.pop();
  }

  /**
 * Populate form data with Gift or Designation data returned from api
 * @param gift IGift used to populate form data
 */
  async setFormValues(gift: IGift) {
    this.logger.context.push("setFormValues");

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    // HACK: We're not going to hear back from the search to know whether or not it was successful
    // so we're just going to assume it was and move on

    this.spinnerService.hide();

    this.logger.debug("Update form values");

    this.editForm.patchValue({ giftId: gift.giftId });
    this.editForm.patchValue({ amount: gift.amount });
    this.amount = gift.amount;

    this.editForm.patchValue({ fundId: gift.designation });
    this.designationName = gift.designation.publicName;
    // }

    this.logger.context.pop();
  }

  get fundId() { return this.editForm.get("fundId"); }
  get amountControl() { return this.editForm.get("amount"); }

}
