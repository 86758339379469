<div class="card">
  <div class='card-header bg-blue text-white'>
    {{pageTitle}}
  </div>
  <div class="card-body">
    <div class="" *ngIf="gifts">
      <ul pb-0 m-0>
        <li *ngFor="let gift of gifts">
          <div class="row">
            <div class="col-lg-8 col-6 emphasized-text designation-name">
              {{ gift.designation.publicName }}
            </div>
            <div class="col-lg-2 col-3">
              {{ gift.amount | currency: 'USD':'symbol':'1.0' }}
            </div>
            <div class="col-lg-2 col-3">
              <div class="btn-group" role="group">
                <button type="button" id="editButton" class="btn btn-edit btn-sm" (click)="onEditGift(gift.giftId)">
                  <i class="fa fa-pencil"></i>
                </button>
                <button type="button" id="deleteButton" class="btn btn-delete btn-sm"
                        [swal]="{title: 'Are you sure you want to delete?'
                          , icon: 'question'
                          , showCancelButton: 'true'
                          , focusCancel: 'true'
                          , text: 'This cannot be undone!'
                          , buttonsStyling: false
                          , customClass: {
                            confirmButton: 'btn btn-swal-confirm-button',
                            cancelButton: 'btn btn-swal-cancel-button'
                          }}"
                        (confirm)="onDeleteGift(gift.giftId)"
                        (dismiss)="onCancel($event)">
                  <i class="fa fa-trash"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row pb-2">
            <div class="col-md-12">
              {{ gift.designation.purposeDescription }}
            </div>
          </div>
          <div class="row border-bottom mb-2 pb-2">
            <div class="col-md-12 less-emphasized-text">
              {{ "Fund Number: " + gift.designation.fundNumber }}
            </div>
          </div>
        </li>
      </ul>
      <div class="row border-bottom mb-3 pb-3">
        <div class="col-3 offset-6 emphasized-text text-nowrap">
          {{ "Total: $" + total }}
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="pb-3 w-100">
        <button type="button" class="btn btn-navigation w-100" id="addGift" (click)="addGift()">
          <i class="fa fa-plus-circle"></i> Add Another Gift
        </button>
      </div>
    </div>
  </div>
</div>
