import { Component, OnInit, Input } from '@angular/core';
import { IDesignation } from "../designation";
import { LoggerService } from "../../shared/logger.service";
import { AppealCodeComponent } from "../gift-add/link-generator/appeal-code/appeal-code.component";
import { IAppealCode } from "../gift-add/link-generator/appealcode";


@Component({
  selector: 'app-fund-description',
  templateUrl: './fund-description.component.html',
  styleUrls: ['./fund-description.component.css'],
  providers: [LoggerService]
})
export class FundDescriptionComponent implements OnInit {

  @Input() fund: IDesignation;
  @Input() showLink: boolean = false;
  
  fundSelectionDescriptionPanelOpen: boolean = true;

  appealCode: IAppealCode;
  
  constructor(private logger: LoggerService) {
    logger.context.push("fund-description");
  }

  onAppealCodeChanged(result: IAppealCode) {
    this.appealCode = result;
  }

  /**
   * Copy the link text to the user's clipboard
   */
  copyClick(directLinkElement) {
    this.logger.context.push("copyClick");
    this.logger.debug("LinkElement: ", directLinkElement.value);
    directLinkElement.select();
    document.execCommand('copy');
    directLinkElement.setSelectionRange(0, 0);
    this.logger.context.pop();
  }

  ngOnInit() {
  }

}
