import { Component, OnInit, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Subscription } from 'rxjs';
import { DonationService } from "@gift/donation/donation.service";
import { AcknowledgeeComponent } from "@gift/gift-review/acknowledgee/acknowledgee.component";
import { ConstantsService } from "@shared/constants.service";

import { LoggerService } from "@shared/logger.service";
import { TextValidators } from '@app/shared/validators/text-validators';

@Component({
  selector: 'app-tribute',
  templateUrl: './tribute.component.html',
  styleUrls: ['./tribute.component.css'],
  providers: [LoggerService]
})
export class TributeComponent implements OnInit {

  inHonorOrMemory: boolean = false;

  tributeSubscription: Subscription;

  constants = ConstantsService;

  constructor(public donationService: DonationService,
    private formBuilder: UntypedFormBuilder,
    private logger: LoggerService) { }

  form: UntypedFormGroup;

  @ViewChild(AcknowledgeeComponent) acknowledgeeComponent: AcknowledgeeComponent;

  submitting: boolean = false;

  /**
   * Capture change event of In Honor or Memory checkbox and create or destroy tribute on donation service
   * @param event
   */
  onInTributeChanged(event) {
    this.logger.context.push("onTributeChanged");
    this.logger.debug("Checked?: ", event.checked);
    if (event.checked) {
      if (!this.donationService.tribute) {
      this.donationService.tribute = {
          tributeDefinition: {
            firstName: "",
            lastName: "",
            type: "",
            description: ""
          }
        }
      }
      // Add validators
      this.logger.debug("Add validators");
      this.firstName.setValidators([Validators.required, Validators.maxLength(ConstantsService.firstNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.lastName.setValidators([Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit), TextValidators.exludeSpecialCharacters()]);
      this.tributeType.setValidators([Validators.required]);
      this.logger.debug("Subscribe to changes");
      this.watchChanges();
    } else {
      this.logger.debug("Unsubscribe from changes to form");
      this.tributeSubscription.unsubscribe();
      this.donationService.tribute = null; // TODO: This appears to work but gives an IDE error
      // Remove validators
      this.logger.debug("Remove validators");
      this.firstName.clearValidators();
      this.lastName.clearValidators();;
      this.tributeType.clearValidators();
      this.firstName.updateValueAndValidity();
      this.lastName.updateValueAndValidity();
      this.tributeType.updateValueAndValidity();

    }
    this.logger.context.pop();
  }

  // Getters for controls
  get firstName() { return this.form.get("firstName"); }
  get lastName() { return this.form.get("lastName"); }
  get tributeType() { return this.form.get("tributeType"); }

  get inHonorOfText() { return ConstantsService.inHonorText; }
  get inMemoryOfText() { return ConstantsService.inMemoryText; }

  /** Watch for changes and update donation object */
  watchChanges(): void {
    this.tributeSubscription = this.form.valueChanges.subscribe(value => {
      this.donationService.tribute.tributeDefinition.firstName = value.firstName;
      this.donationService.tribute.tributeDefinition.lastName = value.lastName;
      this.donationService.tribute.tributeDefinition.type = value.tributeType;
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: [],
      lastName: [],
      tributeType: []
    });

    if (this.donationService.tribute) {
      this.inHonorOrMemory = true;
      // Get initial values
      // TODO: Use class
      this.firstName.setValue(this.donationService.tribute.tributeDefinition.firstName);
      this.lastName.setValue(this.donationService.tribute.tributeDefinition.lastName);
      this.tributeType.setValue(this.donationService.tribute.tributeDefinition.type);
    }

    this.watchChanges();

  }

  ngOnDestroy() {
    this.tributeSubscription.unsubscribe();
  }

}
