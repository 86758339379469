import { Injectable } from '@angular/core';
import { ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { TitleCasePipe } from "@angular/common";

import { ControlError } from "./errors/control-error";
import { LoggerService } from "./logger.service";
@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private titleCase: TitleCasePipe,
    private logger: LoggerService) {

    this.logger.context.push("helper-service");
  }

  getFormValidationErrors(form: UntypedFormGroup): ControlError[] {
    this.logger.context.push("getFormvalidationErrors");
    let errors: ControlError[] = [];

    Object.keys(form.controls).forEach(key => {
      const controlErrors: ValidationErrors = form.get(key).errors;

      if (controlErrors) {
        Object.keys(controlErrors).forEach(keyError => {
          this.logger.debug("Control", key);
          this.logger.debug("Error key: ", keyError);
          this.logger.debug("Error value: ", controlErrors[keyError]);
          var errorVal: string;
          switch (keyError) {
            case "min": {
              errorVal = controlErrors[keyError].min;
              break;
            }
            case "maxlength": {
              // If it is a max length error, retrieve the max length and add it to the error message
              this.logger.debug(`Max length error`);
              var maxLengthError = controlErrors[keyError];
              var maxLength = maxLengthError["requiredLength"];
              this.logger.debug(`Max length: ${maxLength}`);
              this.logger.debug(`Actual length: ${maxLengthError["actualLength"]}`);
              errorVal = `Max Length is ${maxLength}`;
              break;
            }
            default: {
              errorVal = controlErrors[keyError];
            }
          }
          errors.push(new ControlError(this.titleCase.transform(key), keyError, errorVal));
        });
      }
    });
    this.logger.context.pop();
    return errors;

  }

  /** Scrolls to the top of the page */
  static goToTop(): void {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }


}
