import { Component, OnInit, AfterViewChecked, NgZone } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";

import { Donor } from "@gift/donation/donor";
import { HelperService } from "@shared/helper.service";
import { DonationService } from "@gift/donation/donation.service";
import { PaymentType } from "@gift/donation/payment-type.enum";
import { LoggerService } from "@shared/logger.service";
import swal from "sweetalert2";
import { ConstantsService } from "@shared/constants.service";
import { AppConfigService } from "../app-config.service";
import { TextValidators } from '@app/shared/validators/text-validators';


@Component({
  selector: 'app-your-info',
  templateUrl: './your-info.component.html',
  styleUrls: ['./your-info.component.css'],
  providers: [LoggerService]
})
export class YourInfoComponent implements OnInit {
  pageTitle: string = "About You";
  yourInfoForm: UntypedFormGroup;
  addressForm: UntypedFormGroup;

  PaymentType = PaymentType;

  showAddress: boolean = false;
  showPostalCode: boolean = true;
  showState: boolean = true;

  errorMessage: string;
  isCorporate: boolean = false;

  armedForces: boolean = false;

  attempts: number = 0;

  public constants = ConstantsService;

  readonly armedForcesMsg: string = 'Important: Unfortunately, the secure system we currently use to process credit card gifts is unable to accept billing addresses from American Armed Forces locations. We deeply regret this inconvenience and want to assure you that we are actively pursuing a solution with them. In the meantime, we thank you for your desire to make a gift to UC San Diego and would be happy to facilitate your gift over the phone at (858) 246-1090. You can also email us <a href="mailto:onlinegift@ucsd.edu">here</a> with any questions. Thank you for your service to our country and your support of UC San Diego.';

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    public donationService: DonationService,
    private logger: LoggerService,
    private spinnerService: NgxSpinnerService,
    private appConfigService: AppConfigService,
    private titleService: Title,
    private zone: NgZone  ) {
    this.titleService.setTitle(`${ConstantsService.pageTitle} Your Info`);

    this.logger.context.push("your-info");

    // Check if settings has already been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      this.appConfigService.loadSettings().toPromise().then(
        data => {
          AppConfigService.settings = data;
          this.logger.info("Config settings loaded: ", AppConfigService.settings);
          // Use data to update local variables
        });
    } else {
    }
  }

  onReview() {
    this.saveDonorInfo();

    this.router.navigateByUrl("/review");
    HelperService.goToTop();
  }

  onCorporateChange() {
    this.pageTitle = `About You ${(this.isCorporate) ? "& Your Corporation" : ""}`;

    // If corporate box is checked add required to organizationName

    if (this.isCorporate) {
      this.organizationName.setValidators([Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit)]);
    } else {
      this.organizationName.clearValidators();
      this.organizationName.updateValueAndValidity();
      this.organizationName.reset();
    }
  }



  onSubmit() {
    this.logger.context.push("onSubmit");
    // Check if form is valid before submitting
    if (this.yourInfoForm.invalid || this.addressForm.invalid) {
      this.attempts++;
      return;
    }

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    this.saveDonorInfo();

    this.logger.debug("Form valid: ", this.yourInfoForm.valid);

    this.donationService.createDonation().then(
      data => {
        this.logger.debug("Result: ", data);

        // Get checkout url
        var checkoutUri = data.BBSPCheckoutUri;

        this.logger.debug("Checkout: ", checkoutUri);
        this.logger.info(("Routing to BBPS"));
        location.href = checkoutUri;
      },
      error => {
        this.logger.fatal("An error occurred calling Create Donation", JSON.stringify(error, Object.getOwnPropertyNames(error)));
        // If there were no gifts re-route user to the add-gift page
        if (error.message === ConstantsService.noGiftsInCart) {
          swal.fire({
            title: "Oops",
            text: "There appear to be no gifts in your shopping cart. Please add a gift and try again or contact Gift Processing at 858-246-1090 for assistance.",
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-swal-confirm-button'
            }
          });
          this.router.navigateByUrl("/add-gift");
        } else {
          this.spinnerService.hide();
          swal.fire({
            title: "An Error Occurred",
            text: ConstantsService.processingErrorMessage,
            icon: "error",
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-swal-confirm-button'
            }
          });
        }
      });

    this.logger.context.pop();
  }



  saveDonorInfo() {
    this.logger.context.push("saveDonorInfo");
    this.yourInfoForm["controls"].address.patchValue(this.addressForm.value);
    this.logger.debug("Form values: ", this.yourInfoForm.value);
    this.donationService.donor = new Donor(<Donor>this.yourInfoForm.value);
    this.donationService.saveDonor(this.donationService.donor);
    this.donationService.isCorporate = this.isCorporate;
    this.donationService.isAnonymous = this.isAnonymous.value;
    this.logger.context.pop();
  }

  getDonorInfo() {
    this.logger.context.push("getDonorInfo");
    var donor: Donor = this.donationService.donor;
    if (donor) {
      this.yourInfoForm.patchValue(donor);
      this.addressForm.setValue(donor.address);
    } else {
      // If there is no donor info, check a cached donor
      this.donationService.getDonor().then(
        data => {
          this.logger.debug("Donor: ", data);
          if (data) {
            this.logger.debug("isCorporate: ", this.donationService.isCorporate);
            this.isCorporate = this.donationService.isCorporate

            this.yourInfoForm.patchValue(data);
            this.addressForm.patchValue(data.address);
            // TODO: The state does not appear to be populating the control correctly here
          }
        });
    }
    this.showAddress = true;
    this.logger.context.pop();
  }


  /**
   * Captures change event on address component and determines whether to allow payment or display message
   * @param armedForces boolean value of whether armed forces was selected
   */
  onStateChange(armedForces: boolean) {
    this.armedForces = armedForces;
  }


  // Form fields
  get emailAddress() { return this.yourInfoForm.get("emailAddress"); }
  get isAnonymous() { return this.yourInfoForm.get("isAnonymous"); }

  get organizationName() { return this.yourInfoForm.get("organizationName"); }
  get firstName() { return this.yourInfoForm.get("FirstName"); }
  get lastName() { return this.yourInfoForm.get("lastName"); }
  get phone() { return this.yourInfoForm.get("phone"); }


  ngOnInit() {

    this.logger.debug("Amount: ", this.donationService.giftTotal);
    this.yourInfoForm = this.formBuilder.group({
      organizationName: ["", Validators.maxLength(ConstantsService.lastNameCharacterLimit)],
      FirstName: ["", [Validators.required, Validators.maxLength(ConstantsService.firstNameCharacterLimit), TextValidators.exludeSpecialCharacters()]],
      lastName: ["", [Validators.required, Validators.maxLength(ConstantsService.lastNameCharacterLimit), TextValidators.exludeSpecialCharacters()]],
      phone: ["", Validators.required],
      emailAddress: ["", [Validators.email, Validators.required]],
      isAnonymous: [],
      address: this.addressForm
    });

    this.addressForm = this.formBuilder.group({
      country: ["", Validators.required],
      addressLines: ["", [Validators.required, Validators.maxLength(ConstantsService.addressLinesCharacterLimit)]],
      city: ["", [Validators.required, Validators.maxLength(ConstantsService.cityCharacterLimit), TextValidators.exludeSpecialCharacters()]],
      state: ["", Validators.required],
      postalCode: ["", Validators.required]
    });


    this.getDonorInfo();

    this.isCorporate = this.donationService.isCorporate;
    this.isAnonymous.setValue(this.donationService.isAnonymous);
  }



}
