import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
//import { environment } from "../../environments/environment";
import { AppConfigService } from "../app-config.service";
import { IDesignation } from "../gift/designation";
import { LoggerService } from "./logger.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  private apiUrl: string; // = AppConfigService.settings.webApi.url + "/fuzzysearch";
  private httpOptions = {
    headers: new HttpHeaders({
      "ContentType": "applicaiton/json"
    })
  };
  
  constructor(private http: HttpClient,
    private appConfigService: AppConfigService,
    private logger: LoggerService) {

    this.logger.context.push("search-service");
  }


  async checkConfigSettings() {
    this.logger.context.push("checkConfigSettings");
    // TODO: Factor this out into a shared class that can be called from other services
    // Check if settings has alreaby been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
      this.logger.info("Config settings loaded: ", AppConfigService.settings);
    }
    // Use data to update local variables
    this.apiUrl = AppConfigService.settings.webApi.url + "/fuzzysearch";
    this.logger.context.pop();
  }

  async fuzzySearch(searchStr: string): Promise<IDesignation[]> {
    const options = { params: new HttpParams().set('id', searchStr) };

    await this.checkConfigSettings();

    return this.http.get<IDesignation[]>(this.apiUrl, options).toPromise();

  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error("Http error: ", err);
    return throwError(errorMessage);
  }

}
