import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl, NgForm} from "@angular/forms";
import { DonationService } from "../../donation/donation.service";
import { PaymentType } from "../../donation/payment-type.enum";
import { GiftType } from "../../donation/gift-type.enum";
import { LoggerService } from "../../../shared/logger.service";
import { AppConfigService } from "../../../app-config.service"

@Component({
  selector: 'app-select-payment-type',
  templateUrl: './select-payment-type.component.html',
  styleUrls: ['./select-payment-type.component.css'],
  providers: [LoggerService]
})
export class SelectPaymentTypeComponent implements OnInit {

  GiftType = GiftType;
  PaymentType = PaymentType;

  form: UntypedFormGroup;

  submitting: boolean = false;

  constructor(public donationService: DonationService,
    private formBuilder: UntypedFormBuilder,
    private logger: LoggerService,
    private appConfig: AppConfigService) {

    this.logger.context.push("select-payment-type");
  }

  get paymentType() { return this.form.get("paymentType"); }

  ngOnInit() {

    this.form = this.formBuilder.group({
      paymentType: ["", [Validators.required]]
    });

    // Get initial value
    this.paymentType.setValue(this.donationService.paymentType);

    // Watch for changes and update the donation object
    this.paymentType.valueChanges.subscribe(value => {
      this.logger.debug("Payment type changed: ", value);
      this.donationService.paymentType = value;
    });

    // Watch for changes on the gift type so we can set the default payment type
    this.donationService.giftType$.subscribe(giftType => {
      if (giftType === GiftType.Recurring) {
        this.paymentType.setValue(PaymentType.PayrollDeduction);
      } else if (giftType === GiftType.OneTime || giftType === GiftType.PledgePayment){
        this.paymentType.setValue(PaymentType.CreditCard);
      }
    })

  }

}
