
import { NgModule } from '@angular/core';

import { TitleCasePipe } from "@angular/common";

import { AppComponent } from './app.component';


import { YourInfoComponent } from './your-info/your-info.component';


import { GiftModule } from "./gift/gift.module";
import { SharedModule } from "./shared/shared.module";

import { ConfirmationComponent } from "./gift/donation/confirmation/confirmation.component";
import { PayrollDeductionComponent } from './payroll-deduction/payroll-deduction.component';
import { SafePipe } from "./shared/safe.pipe";

import { MatchingGiftComponent } from './matching-gift/matching-gift.component';
import { MatchingGiftSearchComponent } from './matching-gift/matching-gift-search/matching-gift-search.component';
import { FooterComponent } from "./footer/footer.component";

import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";

import { APP_INITIALIZER } from "@angular/core";
import { AppConfigService } from "./app-config.service";

export function appConfigFactory(provider: AppConfigService) {
  return () => provider.loadSettings();
}
@
NgModule({
  declarations: [
    AppComponent,
    YourInfoComponent,
    ConfirmationComponent,
    SafePipe,
    PayrollDeductionComponent,
    MatchingGiftComponent,
    MatchingGiftSearchComponent,
    FooterComponent
  ],
  imports: [

    NgxSpinnerModule,
    GiftModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.DEBUG}),
    SharedModule,
    NgxMaskDirective

  ],
  providers: [
    [TitleCasePipe],
    {provide: APP_INITIALIZER, useFactory: appConfigFactory, deps: [AppConfigService], multi: true},
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
