import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoggerService } from "../../../../shared/logger.service";
import { IAppealCode } from "../appealcode";
import { AppealCodeService } from "../appeal-code.service";

@Component({
  selector: 'app-appeal-code',
  templateUrl: './appeal-code.component.html',
  styleUrls: ['./appeal-code.component.css'],
  providers: [LoggerService]
})
export class AppealCodeComponent implements OnInit {
  @Input() appealCodeOnly: boolean;
  @Output() appealCodeSelectionChanged = new EventEmitter<IAppealCode>();

  hasAppealCode: boolean = false;
  appealCodes: IAppealCode[];
  selectedAppealCode: IAppealCode;

  constructor(private appealService: AppealCodeService,
    private logger: LoggerService) { }

  /**
   * Retrieve appeal codes from CRM
   */
  getAppealCodes(): void {
    this.logger.context.push("getAppealCodes");
    this.appealService.getAppealCodes().then(
      data => {
        this.logger.debug("Appeal codes: ", data);
        this.appealCodes = data;
      });
    this.logger.context.pop();
  }

  onAppealCodeChange(result: IAppealCode): void {
    this.appealCodeSelectionChanged.emit(result);
    this.selectedAppealCode = result;
  }

  /**
   * If has appeal is changed, clear the selected appeal so that it does not get included in the link
   * @param event - Checkbox
   */
  onHasAppealCodeChanged(event) {
    if (!event.checked) {
      this.onAppealCodeChange(null);
    }
  }

  ngOnInit() {
    this.getAppealCodes();
    
    this.logger.debug("appeal code only: ", this.appealCodeOnly);
  }

}
