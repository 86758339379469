import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";

export class TextValidators {

  /** Checks for special characters and returns invalidCharacters error if any are found
   * "-" and ' are allowed
   *
   * @example
   * firstName = new FormControl('', [TextValidators.exludeSpecialCharacters()]);
   *
   * */
  static exludeSpecialCharacters(): ValidatorFn {
    return (control: AbstractControl): {invalidCharacters: "Field contains invalid characters"} | null => {
      if (!control.value){
        return null;
      }

      const regex = new RegExp(/^[a-zA-Z0-9-' ]+$/);
      const valid = regex.test(control.value);
      return valid ? null : { invalidCharacters: "Field contains invalid characters" };
    };
  }

  /** Checks for special characters using custom provided regex and returns invalidCharacters error if any are found
   *
   * @param regex Regular expression of valid characters
   * @example
   * const regex = new RegExp(/^[a-zA-Z0-9-' ]+$/);
   * firstName = new FormControl('', [TextValidators.exludeCustomSpecialCharacters(regex)]);
   *
   * */
  static exludeCustomSpecialCharacters(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): {invalidCharacters: "Field contains invalid characters"} | null => {
      if (!control.value){
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : {invalidCharacters: "Field contains invalid characters"};
    };
  }

}
