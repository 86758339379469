import { Injectable, enableProdMode } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { IAppConfig } from "./app-config";
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { environment } from "../environments/environment";

import { ConstantsService } from "./shared/constants.service";


@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    static settings: IAppConfig;

    constructor(private httpClient: HttpClient) { }

    loadSettings(): Observable<IAppConfig> {
        // TODO: Find a way to incorporate the check for existing settings as well
        // as setting the value here so that no logic is spread around the rest of the application
        var jsonFile: string;
        var configFileName = environment.name === 'dev' ? "config.dev.json" : "config.deploy.json";
        const loc = window.location.href;

        // TODO: find a cleaner way to do this rather than a relative path

        if (ConstantsService.isIe) {
            // If it is IE check url and update path
            if (loc.indexOf("referral") >= 0 ||
              loc.indexOf("edit") >= 0 ||
                (loc.indexOf("confirmation/") >= 0 && !(loc.indexOf("confirmation?t=") >= 0))) {

              jsonFile = `../../bundles/assets/config/${configFileName}`;
            } else {
              jsonFile = `../bundles/assets/config/${configFileName}`;
            }
        } else {
            // If it isn't IE use single path
            jsonFile = `../bundles/assets/config/${configFileName}`;
        }

        var result = this.httpClient.get<IAppConfig>(jsonFile).pipe(
            tap(data => {
                AppConfigService.settings = data;
            }),
            catchError(err => this.handleError(err)));
        return result;
    }


    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';

        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        return throwError(errorMessage);
    }

}
