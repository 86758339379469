import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
// import { environment } from "../../environments/environment";
import { AppConfigService } from "../../../app-config.service"
import { LoggerService } from "../../../shared/logger.service"
import { IAppealCode } from "./appealcode";

@Injectable({
  providedIn: 'root'
})
export class AppealCodeService {
  private apiUrl: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  constructor(private http: HttpClient,
    private appConfigService: AppConfigService,
    private logger: LoggerService) {

    this.logger.context.push("appeal-code-service");
  }

  async checkConfigSettings() {
    this.logger.context.push("checkConfigSettings");
    // TODO: Factor this out into a shared class that can be called from other services
    // Check if settings has already been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
      this.logger.info("Config settings loaded: ", AppConfigService.settings);
    }
    // Use data to update local variables
    this.apiUrl = AppConfigService.settings.webApi.url + "/appealcode";
    
    this.logger.context.pop();
  }

  /**
   * Retrieve appeal codes from web api
   */
  async getAppealCodes(): Promise<IAppealCode[]> {
    this.logger.context.push("getAppealCodes");
    await this.checkConfigSettings();
    var results = this.http.get<IAppealCode[]>(this.apiUrl, this.httpOptions).pipe(tap(data => {
        this.logger.debug("AppealCodes:", data);
      }),
      catchError(err => this.handleError(err))).toPromise();
    this.logger.context.pop();
    return results;
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error("Http error: ", err);
    this.logger.error(errorMessage);

    return throwError(err);
  }

}
