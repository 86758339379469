import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { AppConfigService } from "../app-config.service";

import { LoggerService } from "../shared/logger.service";

@Injectable({
  providedIn: 'root'
})
export class PayrollDeductionService {

  private apiUrl: string;
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(private http: HttpClient,
    private appConfigService: AppConfigService,
    private logger: LoggerService) {

    this.logger.context.push("payroll-deduction-service");
  }

  async addPayrollDeduction(payrollCycle: string): Promise<string> {
    this.logger.context.push("addPayrollDeduction");
    await this.checkConfigSettings();
    
    this.logger.debug("Payroll cycle: ", payrollCycle);

    var payrollDeduction = { payrollCycle: payrollCycle };

    this.logger.debug("Payroll deduction: ", payrollDeduction);

    var result = this.http.post<string>(this.apiUrl, payrollDeduction, this.httpOptions).pipe(tap(data => this.logger.debug("Result: ", data))).toPromise();
    this.logger.context.pop();
    return result;
  }

  async checkConfigSettings() {
    // TODO: Factor this out into a shared class that can be called from other services
    // Check if settings has already been set
    if (AppConfigService.settings == null) {
      // If not, load it now
      AppConfigService.settings = await this.appConfigService.loadSettings().toPromise();
      this.logger.info("Config settings loaded: ", AppConfigService.settings);
    }
    // Use data to update local variables
    this.apiUrl = AppConfigService.settings.webApi.url + "/payrolldeduction";

  }

 

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.debug("Http error: ", err);
    return throwError(errorMessage);
  }

}
