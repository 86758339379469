import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { HttpParams } from "@angular/common/http";
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from "@angular/forms";

import { DonationService } from "../donation/donation.service"
import { GiftType } from "../donation/gift-type.enum";
import { PaymentType } from "../donation/payment-type.enum";
import { SelectPaymentTypeComponent } from "./select-payment-type/select-payment-type.component";
import { SelectGiftTypeComponent } from "./select-gift-type/select-gift-type.component";
import { TributeComponent } from "./tribute/tribute.component";
import { RecurrenceComponent } from "./recurrence/recurrence.component";
import { HelperService } from "../../shared/helper.service";
import { LoggerService } from "../../shared/logger.service";
import { Title } from '@angular/platform-browser';
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "../../shared/constants.service";

@Component({
  selector: 'app-gift-review',
  templateUrl: './gift-review.component.html',
  styleUrls: ['./gift-review.component.css']
})
export class GiftReviewComponent implements OnInit {

  GiftType = GiftType;
  PaymentType = PaymentType;

  total: number;
  displayGifts: boolean = false;

  inHonorOrMemory: boolean;

  constants = ConstantsService;

  giftReviewForm: UntypedFormGroup;

  get specialInstructions() { return this.giftReviewForm.get("specialInstructions"); }


  @ViewChild(SelectPaymentTypeComponent, { static: true }) paymentTypeComponent: SelectPaymentTypeComponent;
  @ViewChild(SelectGiftTypeComponent, { static: true }) giftTypeComponent: SelectGiftTypeComponent;
  @ViewChild(TributeComponent) tributeComponent: TributeComponent;
  @ViewChild(RecurrenceComponent) recurrenceComponent: RecurrenceComponent;


  attempts: number = 0;

  constructor(private router: Router,
    public donationService: DonationService,
    private spinnerService: NgxSpinnerService,
    private logger: LoggerService,
    private titleService: Title,
    private formBuilder: UntypedFormBuilder) {

      this.titleService.setTitle(`${ConstantsService.pageTitle} Review`);

    this.logger.context.push("gift-review");
  }

  /**
   * Route to Your Info component
   */
  yourInfoClick(): void {
    this.logger.debug("Location: ", window.location.host);
    // Validate child forms
    this.paymentTypeComponent.submitting = true;
    this.giftTypeComponent.submitting = true;
    if (this.tributeComponent) { this.tributeComponent.submitting = true; }
    if (this.recurrenceComponent) {
      this.recurrenceComponent.submitting = true;}

    if (this.paymentTypeComponent.form.invalid || this.giftTypeComponent.form.invalid || this.giftReviewForm.invalid ||
      (this.tributeComponent && this.tributeComponent.form.invalid) ||
      (this.tributeComponent && this.tributeComponent.acknowledgeeComponent
        && this.tributeComponent.acknowledgeeComponent.acknowledgeeForm
        && this.tributeComponent.acknowledgeeComponent.acknowledgeeForm.invalid) ||
      (this.recurrenceComponent && ((!this.donationService.recurrence.frequency && this.donationService.paymentType === PaymentType.CreditCard) || (this.recurrenceComponent.form && this.recurrenceComponent.form.invalid)))) {
      this.attempts ++;
      return;
    }

    switch (this.donationService.paymentType) {
      case PaymentType.PayrollDeduction:

        var host: string = window.location.host;
        var localhost: boolean = host.includes("localhost");
        var returnUrl: string = `/home/payrollconfirmation/`;


        // TODO: this requires an update if we change the virtual directory name. Update to retrieve virtual directory
        returnUrl = `${(localhost) ? "http://" : "https://"}${host}${(!localhost) ? "/giving" : ""}${returnUrl}`;

        this.logger.debug("ReturnUrl: ", returnUrl);

        window.location.href = `${returnUrl}`;

        break;
      case PaymentType.CreditCard:
      case PaymentType.Pledge:
        this.router.navigateByUrl("/your-info");
        HelperService.goToTop();
        break;
    }

  }

  /**
   * Checks for query string parameters and attempts to parse them and route to component accordingly
   */
  parseParams(): void {

    this.logger.loggerReady().then(
      data => {

        this.logger.context.push("parseParams");
        const confirmationParam = "t";
        const fundIdParam = "id";
        const appealIdParam = "appealId";
        const url: string = window.location.href;

        this.logger.debug("Url: ", url);

        var confirmationValue: string;
        var fundIdValue: string;
        var appealIdValue: string;

        // If there is a query string in the url attempt to parse for known params
        if (url.includes("?")) {
          const httpParams = new HttpParams({ fromString: url.split("?")[1] });

          // Attempt to get a confirmation value (t)
          confirmationValue = httpParams.get(confirmationParam);

          this.logger.debug("Param: ", confirmationValue);

          if (confirmationValue) {
            this.displayGifts = false;
            this.router.navigate(["/confirmation/", confirmationValue]);
          }

          // If there is no confirmation param attempt to get a fund id
          fundIdValue = httpParams.get(fundIdParam);

          this.logger.debug("Fund ID: ", fundIdValue);

          if (fundIdValue) {
            this.displayGifts = false;
            this.router.navigate(["/gift-referral/", fundIdValue]);
          }

          // Try to get an appeal id and route to add-gift
          appealIdValue = httpParams.get(appealIdParam);
          this.logger.debug("Appeal Id: ", appealIdValue);
          if (appealIdValue) {
            this.logger.debug("Appeal id found. Route to add-gift with id");
            this.displayGifts = false;
            this.router.navigate(["/add-gift/", appealIdValue]);
          }

        }

        // If it isn't a confirmation or referral display gifts
        this.displayGifts = true;

        this.logger.context.pop();
      });
  }

  // Event Listeners for child components

  /**
   * Capture in honor or memory boolean and update for Acknowledgee
   * @param inHonorOrMemory
   */
  onTributeChanged(inHonorOrMemory: boolean) {
    this.inHonorOrMemory = inHonorOrMemory;
  }

  /**
   * Capture updated total from Gift List
   * @param total
   */
  onTotalChanged(total: number) {
    this.total = total;
    this.donationService.giftTotal = total;
  }

  ngOnInit(): void {

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    // Check for param values before loading page
    this.parseParams();

    this.giftReviewForm = this.formBuilder.group({
      specialInstructions: ["", Validators.maxLength(ConstantsService.attributeTextLength)]
    });



  }

}
