import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LoggerService } from "../../../shared/logger.service";

@Component({
  selector: 'app-gift-amount',
  templateUrl: './gift-amount.component.html',
  styleUrls: ['./gift-amount.component.css'],
  providers: [LoggerService]
})
export class GiftAmountComponent implements OnInit {
  @Output() amountChanged = new EventEmitter<number>();
  @Input() amount: number;
  useOtherAmount: boolean = false;

  constructor(private logger: LoggerService) {

    this.logger.context.push("gift-amount");
  }

  amountChange(amount: number) {
    this.logger.context.push("amountChange");
    this.logger.debug("Amount changed: ", amount);
    this.amountChanged.emit(amount);
    this.amount = amount;
    this.logger.context.pop();
  }

  /**
   * Capture the amount selected on the form using the click event
   * @param event
   */
  onAmountOptionsClick(event) {
    this.logger.context.push("onAmountOptionsClick");
    this.logger.debug("Event: ", event);
    let target = event.target || event.srcElement || event.currentTarget;

    // this.logger.debug("Target: ", target);
 
    if (target.children.length > 0) {
      var optionId = target.children[0].id;

      var optionAmount = optionId.substring(6);

      if (!isNaN(optionAmount)) {
        /*this.amount = +optionAmount;
        this.addForm.patchValue({ amount: optionAmount });
        */
        this.amount = +optionAmount;
        this.amountChanged.emit(+optionAmount);
      } 
      this.useOtherAmount = optionId === "optionOther";
    }
    this.logger.context.pop();
  }

  /**
   * Capture changes to the other amount field
   * @param amount
   */
  onOtherAmountChanged(amount: number) {
    this.amountChanged.emit(+amount);
  }
  
  ngOnInit() {
    this.logger.context.push("ngOnInit");

    this.logger.debug("Amount: ", this.amount);

    // If the amount is not one of the standard options use OtherAmount
    this.useOtherAmount = !([-1, 25, 50, 100, 250, 500, 1000, 2500, 5000].includes(this.amount));

    if (this.amount === -1) {
      this.amount = 0;
    }
    
    this.logger.debug("Amount: ", this.amount);
    this.logger.debug("OtherAmount", this.useOtherAmount);
    
    this.logger.context.pop();
  }

}
