import { Component, OnInit, Input } from '@angular/core';
import { LoggerService } from "../../../../shared/logger.service";
import { IDesignation } from "../../../designation";
import { IAppealCode } from "../appealcode";

@Component({
  selector: 'app-direct-link',
  templateUrl: './direct-link.component.html',
  styleUrls: ['./direct-link.component.css'],
  providers: [LoggerService]
})
export class DirectLinkComponent implements OnInit {

  @Input() fund: IDesignation;
  @Input() appealCode: IAppealCode;

  constructor(private logger: LoggerService) {
    logger.context.push("direct-link");
  }


  /**
   * Copy the link text to the user's clipboard
   */
  copyClick(directLinkElement) {
    this.logger.context.push("copyClick");
    this.logger.debug("LinkElement: ", directLinkElement.value);
    directLinkElement.select();
    document.execCommand('copy');
    directLinkElement.setSelectionRange(0, 0);
    this.logger.context.pop();
  }

  ngOnInit() {
  }

}
