<app-progress-bar [progressStep]="2"></app-progress-bar>

<div class="col-md-6 offset-md-3">

  <app-gift-list (totalChanged)="onTotalChanged($event)" *ngIf="displayGifts"></app-gift-list>

  <app-select-gift-type></app-select-gift-type>

  <app-select-payment-type></app-select-payment-type>

  <app-recurrence [total]="total"
                  [recurring]="donationService.giftType === GiftType.Recurring"
                  *ngIf="donationService.giftType === GiftType.Recurring">
  </app-recurrence>

  <app-tribute (inHonorOrMemoryChanged)="onTributeChanged($event)"
               *ngIf="donationService.giftType !== GiftType.Recurring">
  </app-tribute>

  <div *ngIf="attempts >= 3 && !paymentTypeComponent.form.valid">
    <app-validation-failure [form]="paymentTypeComponent.form"></app-validation-failure>
  </div>

  <div *ngIf="attempts >= 3 && !giftTypeComponent.form.valid">
    <app-validation-failure [form]="giftTypeComponent.form"></app-validation-failure>
  </div>

  <div *ngIf="attempts >= 3 && !tributeComponent.form.valid">
    <app-validation-failure [form]="tributeComponent.form"></app-validation-failure>
  </div>
  
  <div *ngIf="attempts >= 3 && tributeComponent.acknowledgeeComponent && !tributeComponent.acknowledgeeComponent.acknowledgeeForm.valid">
    <app-validation-failure [form]="tributeComponent.acknowledgeeComponent.acknowledgeeForm"></app-validation-failure>
  </div>
  <form [formGroup]="giftReviewForm">
    <div class="form-group mt-3">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Special Instructions (optional)</mat-label>
        <textarea matInput id="specialInstructions" formControlName="specialInstructions" [(ngModel)]="donationService.comments"></textarea>
        <mat-error *ngIf="specialInstructions.errors?.maxlength">{{ constants.maxLengthMessage }} is {{ constants.attributeTextLength }} characters</mat-error>
      </mat-form-field>
    </div>
  </form>
  

  <div class="pb-3 d-flex">
    <button type="button" id="btn-your-info" class="btn btn-navigation w-100" (click)="yourInfoClick()">
      <i class="fa fa-chevron-right"></i>Next: Your Info
    </button>
  </div>
</div>


